import React from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import NoItemsMessage from '~/components/NoItemsMessage';
import PropTypes from 'prop-types';

export default function SustainableDevelopmentodssCards({ odss = [] }) {
  if (!odss.length) {
    return <NoItemsMessage itemName="Objetivo para o Desenvolvimento Sustentável" gender="masculino" />;
  }

  return (
    <div style={{ width: "100%" }}>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        spaceBetween={16}
        slidesPerView={2}
        breakpoints={{
          600: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          900: {
            slidesPerView: 4,
            spaceBetween: 32,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 32,
          }
        }}
      >
        {odss.map((ods) => (
          <SwiperSlide key={ods.id}>
            <a href={ods.url_ods} target="_blank" rel="noopener noreferrer">
              <Box
                component="img"
                src={ods.logo_url}
                alt={`ODS ${ods.id}`}
                sx={{
                  width: 130,
                  height: 130,
                  objectFit: 'contain'
                }}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

SustainableDevelopmentodssCards.propTypes = {
  odss: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      url_ods: PropTypes.string.isRequired,
      logo_url: PropTypes.string.isRequired
    })
  )
};
