import React from "react";
import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import CauseCard from "~/components/Cause/Card";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import CausesClient from "~/single-page/api/CausesClient";
import { Box } from "@mui/material";
import NoItemsMessage from "~/components/NoItemsMessage";

const CauseGrid = () => {
  const {
    data: causes = [],
    pending,
    success,
  } = useApiCall({
    apiCall: CausesClient.index,
    init: true,
  });

  if (pending) {
    return (
      <Grid container spacing={4} justifyContent="center">
        {Array.from(new Array(6)).map((_, index) => (
          <Grid 
            item
            xs={12}
            md={6}
            key={index}
          >
            <Box display="flex" justifyContent="center">
              <Skeleton variant="rectangular" height={118} />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!success || !causes.length) {
    return <NoItemsMessage itemName="causa" gender="feminino" />;
  }

  return (
    <Grid container spacing={4} justifyContent="center">
      {causes.map((cause, index) => (
        <Grid 
          item
          xs={12}
          sm={12}
          md={6}
          xl={6}
          key={index}
        >
          <Box display="flex" justifyContent="center">
            <CauseCard cause={cause} key={cause.name} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CauseGrid;
