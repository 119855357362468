import axiosClient from "./axiosClient";
import ROUTES from "./api_routes";

async function index() {
  return axiosClient.get(ROUTES.GET_CAUSES);
}

async function show(id) {
  return axiosClient.get(ROUTES.GET_CAUSE(id));
}

const CausesClient = {
  index,
  show
};

export default CausesClient;
