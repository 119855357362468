import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, TextField, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { QRCodeSVG } from 'qrcode.react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

export default function DonationModal({ open, onClose, ngo }) {
  const [copied, setCopied] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(ngo.pix_qr_code_link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset após 2 segundos
    } catch (err) {
      console.error('Erro ao copiar:', err);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" fontWeight={700}>
          {ngo.name}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: 'grey.500',
            '&:hover': {
              color: 'grey.700',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Sua doação faz a diferença! Com ela, podemos continuar nosso trabalho 
            de impacto social e transformar ainda mais vidas. Qualquer valor é bem-vindo 
            e será utilizado com transparência em nossos projetos.
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 2 }}>
            Para fazer sua doação, copie o código PIX abaixo:
          </Typography>

          <TextField
            fullWidth
            value={ngo.pix_qr_code_link}
            variant="outlined"
            onClick={handleCopyClick}
            InputProps={{
              readOnly: true,
              sx: { 
                backgroundColor: '#f5f5f5',
                fontFamily: 'monospace',
                cursor: 'pointer'
              }
            }}
            helperText={copied ? "Código copiado!" : ""}
            sx={{ mb: 1 }}
          />
          
          <Typography variant="caption" color="text.secondary">
            Após copiar o código, utilize-o em seu aplicativo bancário para realizar a transferência.
          </Typography>

          {!isMobile && ngo.pix_qr_code_link && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <QRCodeSVG 
                value={ngo.pix_qr_code_link} 
                size={200}
                level="H"
                includeMargin={true}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, pb: 2 }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{ 
            color: '#294BB6',
            borderColor: '#294BB6'
          }}
        >
          Cancelar
        </Button>
        <Button 
          onClick={handleCopyClick}
          variant="contained"
          startIcon={<ContentCopyIcon />}
          sx={{ 
            backgroundColor: '#294BB6',
            '&:hover': {
              backgroundColor: '#294BB6',
              opacity: 0.9
            }
          }}
        >
          {copied ? 'Código copiado!' : 'Copiar código PIX'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}