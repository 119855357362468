import * as React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LinkButton from "./LinkButton";
import { useRailsContext } from "../single-page/contexts/RailsContext";
import { Link } from "react-router-dom";
import PrefeituraLogo from "~/images/illustrations/logo-prefeitura-do-recife.svg";
import { APP_ROUTES } from "~/single-page/constants/routes";

const navBarHeight = "88px";

const NavBar = ({ toggleDrawer }) => {
  const { isAuthenticated } = useRailsContext();
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <AppBar
      position="fixed"
      sx={{
        height: navBarHeight,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        width: "100vw",
        zIndex: 1200, // Garantir que está acima de outros elementos
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          width: "100vw",
          justifyContent: "space-between", // Melhor distribuição dos elementos
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ 
            mr: 2, 
            display: isMobile ? 'flex' : 'none'
          }}
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          variant="h6"
          component={Link}
          to={APP_ROUTES.ROOT}
          sx={{
            display: "flex",
            flexGrow: 1,
            ml: 4,
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img src={PrefeituraLogo} alt="Logo da Prefeitura do Recife" />
        </Typography>

        <Box 
          sx={{ 
            display: "flex", 
            alignItems: "center", 
            gap: 2, 
            mr: 4,
          }}
        >
          {isAuthenticated ? (
            <>
              <LinkButton
                color="inherit"
                variant="text"
                sx={{
                  fontSize: "16px",
                  lineHeight: "25.6px",
                  display: { xs: "none", md: "inline-flex" },
                }}
                to={APP_ROUTES.CONTACT_US}
              >
                Fale conosco
              </LinkButton>
              <LinkButton
                color="secondary"
                sx={{ 
                  display: { xs: "none", md: "inline-flex" },
                }}
                to={APP_ROUTES.SIGN_IN}
              >
                Entrar
              </LinkButton>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
