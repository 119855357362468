import React, { useState } from 'react';
import {
  Avatar,
  TextField,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { toast } from 'react-toastify';
import AuthorizationClient from '../api/AuthorizationClient';
import LoadingButton from '../../components/LoadingButton.jsx';

const rdmcolor = '#D12E5E';

export default function RequestPasswordReset() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const requestPasswordReset = (e) => {
    e.preventDefault();
    document.activeElement.blur();
    toast.dismiss();
    setLoading(true);

    AuthorizationClient.requestPasswordReset({ email })
      .then(() => {
        toast.success('Olhe sua caixa de e-mail para concluir o processo');
        navigate('/public/sign_in');
      })
      .catch((response) => {
        if (response.status === 422) {
          setEmailError(response.data.errors.email.join(', '));
        } else {
          setEmailError('');
        }
        toast.error(response.data?.error || 'Erro ao processar solicitação.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        mt: 3,
      }}
    >
      <Avatar sx={{ bgcolor: rdmcolor, mb: 1 }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Recuperar minha senha
      </Typography>
      <Box
        component="form"
        onSubmit={requestPasswordReset}
        noValidate
        sx={{ width: '100%', mt: 1 }}
      >
        <TextField
          required
          id="email"
          label="Email"
          name="email"
          fullWidth
          value={email}
          error={!!emailError}
          helperText={emailError}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
          autoFocus
          sx={{ mt: 2 }}
        />

        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Recuperar minha senha
        </LoadingButton>
      </Box>
      <Grid container>
        <Grid item xs>
          <Typography
            component={Link}
            to="/public/sign_in"
            variant="body2"
            sx={{ textDecoration: 'none' }}
          >
            Entrar
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            component={Link}
            to="/public/sign_up"
            variant="body2"
            sx={{ textDecoration: 'none' }}
          >
            Não possui uma conta? Cadastre-se
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
