import axiosClient from "./axiosClient";
import ROUTES from "./api_routes";

async function galleryNGO(id) {
  return axiosClient.get(ROUTES.GET_GALLERY_NGO(id));
}

async function galleryAction(id) {
  return axiosClient.get(ROUTES.GET_GALLERY_ACTION(id));
}


const GalleryNGOClient = {
  galleryNGO,
  galleryAction
};

export default GalleryNGOClient;
