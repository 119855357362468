import React from "react";

export default function CharityIcon() {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.7139 2.13428C9.86935 2.13428 8.37402 3.66668 8.37402 5.51125C8.37402 7.35581 9.86932 8.85111 11.7139 8.85111C13.5585 8.85111 15.0537 7.35581 15.0537 5.51125C15.0537 3.66668 13.5584 2.13428 11.7139 2.13428Z" fill="#009FE3" />
        <path d="M14.2027 13.6197C12.7518 13.6197 12.2555 15.351 12.2506 15.3684C12.2491 15.3737 12.2435 15.3761 12.2418 15.3813C12.2418 15.3813 12.242 15.3813 12.242 15.3812C12.1702 15.6135 11.9598 15.7745 11.7157 15.7755C11.7151 15.7755 11.7146 15.7755 11.7135 15.7755C11.4651 15.7755 11.2465 15.6097 11.178 15.3706C11.1579 15.2988 10.6627 13.6196 9.22545 13.6196C8.12359 13.6196 7.29297 14.5416 7.29297 15.7647C7.29297 17.2204 8.60576 18.6815 10.7812 20.5177C10.9669 20.6742 11.1579 20.8354 11.3537 21.0018C11.5612 21.1781 11.8663 21.1786 12.0739 21.0024C12.2695 20.8362 12.4602 20.6754 12.6452 20.5188C14.3003 19.1226 16.1351 17.3277 16.1351 15.7646C16.1351 14.5416 15.3046 13.6197 14.2027 13.6197Z" fill="#009FE3" />
        <path d="M14.5528 8.90674C13.7846 9.56361 12.8049 9.96436 11.7139 9.96436C10.6229 9.96436 9.64319 9.56358 8.87503 8.90674C5.75782 9.21842 3.36426 11.8384 3.36426 15.0113V15.7906C3.36426 17.5941 4.06563 19.2862 5.33481 20.5554C5.33481 20.5665 5.33481 20.5665 5.34595 20.5665C5.69106 20.9116 6.16978 21.112 6.65962 21.1232C7.47358 21.1028 8.13202 20.4853 8.24045 19.691C7.2028 18.625 6.17954 17.2589 6.17954 15.7647C6.17954 13.8631 7.63238 12.5063 9.22014 12.5063C9.22122 12.5063 9.22204 12.5065 9.22315 12.5065C9.22389 12.5065 9.22456 12.5063 9.22534 12.5063C10.2731 12.5063 11.1485 13.0826 11.7145 13.9257C12.2857 13.0746 13.1374 12.5063 14.2025 12.5063C14.2033 12.5063 14.2039 12.5065 14.2047 12.5065C14.2058 12.5065 14.2066 12.5063 14.2077 12.5063C15.8944 12.5063 17.2483 13.9531 17.2483 15.7647C17.1958 15.9056 17.4742 17.2784 15.1859 19.676C15.2881 20.4774 15.9492 21.1026 16.7683 21.1232C17.2581 21.112 17.7368 20.9116 18.0819 20.5665C18.0931 20.5665 18.0931 20.5665 18.0931 20.5554C19.3622 19.2862 20.0636 17.5941 20.0636 15.7906V15.0113C20.0636 11.8384 17.67 9.21842 14.5528 8.90674Z" fill="#009FE3" />
      </svg>
    </>
  )
}
