import axiosClient from "./axiosClient.js";
import ROUTES from "./api_routes.js";

async function actionManagements() {
  return axiosClient.get(ROUTES.GET_ACTION_MANAGEMENTS);
}

async function actionManagementsByOng(ongId) {
  return axiosClient.get(ROUTES.GET_ONG_ACTION_MANAGEMENTS(ongId));
}

async function actionManagement(id) {
  return axiosClient.get(ROUTES.GET_ACTION_MANAGEMENT(id));
}

const ActionManagementsClient = {
  actionManagements,
  actionManagement,
  actionManagementsByOng,
};

export default ActionManagementsClient;
