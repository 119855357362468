import React from "react";
import { Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";

const NoItemsMessage = ({ itemName, errorMessage, gender }) => {
  const itemLabel = gender === 'feminino' ? `Nenhuma ${itemName} cadastrada` : `Nenhum ${itemName} cadastrado`;

  return (
    <Box 
      sx={{
        width: "100%",
        height: 484,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "grey.100",
        borderRadius: 1
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 48, color: "grey.500", mb: 2 }} />
      <Typography variant="body1" color="text.secondary">
        {errorMessage || itemLabel}
      </Typography>
    </Box>
  );
};

export default NoItemsMessage;
