export const APP_ROUTES = {
  ROOT: "/",
  EVENTS: "/events",
  EVENT: "/event",
  SIGN_IN: "/sign_in",
  SIGN_UP: "/sign_up",
  RESET_PASSWORD: "/reset_password",
  REQUEST_PASSWORD_RESET: "/request_password_reset",
  CONTACT_US: "/contact_us",
  OPPORTUNITIES: "/opportunities",
  BLOOD_DONATIONS: "/blood_donations",
  NGOS: "/ngos",
  NGO: "/ngo",
  GALLERY_NGO: "/gallery_ngo",
  ACTION_MANAGEMENTS: "/action_managements",
  ACTION_MANAGEMENT: "/action_management",
  GALLERY_ACTION: "/gallery_action",
  CAUSES: "/causes",
  CAUSE_NGOS: (id) => `/causes/${id}/ngos`,
  KNOWLEDGE_TRAILS: "/knowledge_trails",
  ACTIVE_NOTICES: "/active_notices",
};
