import { useState } from 'react';
import { useRailsContext } from '~/single-page/contexts/RailsContext';
import AuthorizationClient from '~/single-page/api/AuthorizationClient';
import { toast } from 'react-toastify';
import { APP_ROUTES } from '~/single-page/constants/routes';

export const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { setFlash } = useRailsContext();

  const signIn = async (email, password) => {
    setLoading(true);
    try {
      await AuthorizationClient.signIn({ email, password });
      const flashNotice = "Login efetuado com sucesso!"
      setFlash({notice: flashNotice });

      window.location.href = `/site?flash_notice=${flashNotice}`;
    } catch (error) {
      toast.error('Erro ao processar solicitação.');
      if (error.response?.status === 401) {
        setErrors({ email: 'Email ou senha inválidos' });
      }
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      await AuthorizationClient.signOut();
      window.location.href = `${APP_ROUTES.SIGN_IN}?flash_notice=Você não está mais logado no sistema.`;
    } catch (error) {
      toast.error('Erro ao processar solicitação.');
      console.error(error);
    }
  };

  return { signIn, signOut, loading, errors };
};
