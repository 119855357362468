import * as React from "react";
import {
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Paper,
  Box,
  Grid,
  useTheme,
} from "@mui/material";
import LinkButton from "~/components/LinkButton";
import CircleIcon from "@mui/icons-material/Circle";
import { APP_ROUTES } from "~/single-page/constants/routes";
import { useNavigate, Link } from 'react-router-dom';

const CauseCard = ({ cause }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = () => {
    navigate(`${APP_ROUTES.NGOS}?causeId=${cause.id}`);
  };

  return (
    <Paper
      sx={{
        px: { xs: "16px", md: "20px" },
        py: { xs: "12px", md: "16px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "400px",
        height: "530px",
        bgcolor: "white",
        maxWidth: "100%",
        position: "relative",
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: theme.shadows[3],
          transform: 'translateY(-2px)'
        }
      }}
      variant="outlined"
      className="cause-card"
    >
      <Box 
        sx={{ 
          width: "100%",
          pb: "60px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: "bold", 
            marginBottom: "10px", 
            textAlign: "center",
            width: "100%" 
          }}
        >
          {cause.name.slice(0, 30)}
        </Typography>

        <Divider 
          flexItem 
          variant="middle" 
          sx={{ 
            width: '100%',
            maxWidth: "360px",
            margin: "0 auto 15px auto"
          }} 
        />

        <Box
          sx={{
            width: '100%',
            height: '200px',
            maxWidth: "360px",
            margin: '0 auto',
          }}
        >
          <img
            src={cause.cover_image_url}
            alt={cause.name}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "4px"
            }}
          />
        </Box>

        <Divider 
          flexItem 
          variant="middle" 
          sx={{ 
            width: '100%',
            maxWidth: "360px",
            margin: "15px auto"
          }} 
        />

        <Grid 
          container 
          spacing={1} 
          sx={{ 
            width: "100%", 
            maxWidth: "360px",
            margin: '0 auto',
            padding: { xs: '0', md: '0 5px' }
          }}
        >
          {cause.ongs && cause.ongs.length > 0 ? (
            cause.ongs.slice(0, 8).map((ong, index) => (
              <Grid item xs={6} key={index}>
                <ListItem sx={{ 
                  padding: { xs: '0', md: '0 4px' },
                  justifyContent: 'flex-start' 
                }}>
                  <ListItemIcon sx={{ 
                    minWidth: "auto", 
                    mr: { xs: 0.5, sm: 0.75, md: 1 }
                  }}>
                    <CircleIcon color="primary" sx={{ fontSize: 6 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link
                        to={`${APP_ROUTES.NGO}/${ong.id}`}
                        style={{ 
                          textDecoration: 'none',
                          color: 'inherit'
                        }}
                        sx={{
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        {ong.name}
                      </Link>
                    }
                    primaryTypographyProps={{ 
                      variant: "caption", 
                      sx: { 
                        whiteSpace: 'nowrap', 
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: "bold",
                        fontSize: { 
                          xs: '0.7rem', 
                          sm: '0.72rem', 
                          md: '0.75rem' 
                        },
                        textAlign: "left",
                        maxWidth: { 
                          xs: '120px', 
                          sm: '130px', 
                          md: '150px' 
                        },
                        display: 'block'
                      } 
                    }}
                  />
                </ListItem>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="caption" sx={{ textAlign: "center", color: "gray" }}>
                Nenhuma ONG ou projeto disponível
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          left: "20px",
          right: "20px",
        }}
      >
        <LinkButton 
          fullWidth 
          sx={{ 
            maxWidth: "360px",
            margin: '0 auto',
            display: 'block',
            textAlign: "center",
            "& .MuiButton-label": {
              justifyContent: "center",
              textAlign: "center"
            }
          }} 
          to={APP_ROUTES.CAUSE_NGOS(cause.id)}
          size="large"
        >
          Ver todas
        </LinkButton>
      </Box>
    </Paper>
  );
};

export default CauseCard;
