import * as React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import GoBackButton from "~/components/GoBackButton";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { useRailsContext } from "~/single-page/contexts/RailsContext";
import "react-toastify/dist/ReactToastify.css";
import "~/single-page/styles/main.scss";
import { theme } from "~/theme/theme";

const signInTheme = theme;

export default function AuthLayout() {
  const { flash } = useRailsContext();

  React.useEffect(() => {
    if (flash?.notice) {
      toast.info(flash.notice);
    }
    if (flash?.alert) {
      toast.warning(flash.alert);
    }
  }, [flash]);

  return (
    <>
      <ToastContainer autoClose={8000} />
      <Container component="main" maxWidth="xs">
        <ThemeProvider theme={signInTheme}>
          <CssBaseline />
          <Box
            sx={{
              position: "absolute", // Position GoBackButton absolutely
              top: 16, // Distance from the top of the screen
              left: 16, // Distance from the left of the screen
              zIndex: 1101, // Ensures it is above other components
            }}
          >
            <GoBackButton />
          </Box>
          <Box>
            <React.Suspense fallback={<CircularProgress />}>
              <Outlet />
            </React.Suspense>
          </Box>
        </ThemeProvider>
      </Container>
    </>
  );
}
