import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "~/single-page/routes";
import { ErrorBoundary } from "react-error-boundary";
import RailsProvider from "~/single-page/providers/RailsProvider";
import { ThemeProvider } from "~/single-page/providers/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
}

function AppRoot({ parameters }) {
  return (
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <RailsProvider
          flash={parameters.flash}
          currentUser={parameters.current_user}
        >
          <ThemeProvider>
            <CssBaseline />
            <RouterProvider 
              router={router} 
              future={{
                v7_startTransition: true,
                v7_fetcherPersist: true,
                v7_normalizeFormMethod: true,
                v7_partialHydration: true,
                v7_prependBasename: true,
                v7_relativeSplatPath: true,
                v7_skipActionErrorRevalidation: true
              }}
            />
          </ThemeProvider>
        </RailsProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

const root_div = document.getElementById("root");
const parameters = JSON.parse(root_div.dataset["parameters"]);

ReactDOM.createRoot(root_div).render(<AppRoot parameters={parameters} />);
