import React, { useState } from "react";
import { Stack, Box, Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Skeleton from "@mui/material/Skeleton";
import EventCard from "~/components/Events/Card";
import EventsClient from "../api/EventsClient";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import SearchField from '~/components/SearchField';
import NoItemsMessage from "~/components/NoItemsMessage";
import { useScrollToSection } from "~/single-page/hooks/useScrollToSection";

export default function EventsPage() {
  const [searchTerm, setSearchTerm] = useState('');
  
  const {
    data: events = [],
    success,
    pending,
  } = useApiCall({
    apiCall: EventsClient.events,
    init: true,
  });

  useScrollToSection();

  const filteredEvents = events.filter(event => {
    return event.title.toLowerCase().includes(searchTerm);
  });

  return (
    <Stack spacing={4} sx={{ maxWidth: '100%' }}>
      <Box>
      {console.log(events)}
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2, mt: 2, textAlign: 'center' }}>
          Agenda de Eventos
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
          Participe dos eventos organizados por ONGs e movimentos sociais.
          Cada evento é uma oportunidade de fazer a diferença e contribuir com causas importantes.
        </Typography>
      </Box>

      <SearchField 
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        label="Pesquisar eventos..."
      />
      
      <Divider />
      
      <Box>
        <Grid 
          container 
          spacing={3}
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              sm: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)'
            },
            gap: 3
          }}
        >
          {pending &&
            Array.from(new Array(6)).map((_, index) => (
              <Grid key={index}>
                <Skeleton 
                  variant="rectangular" 
                  height={180} 
                  sx={{ borderRadius: 1 }}
                  animation="wave"
                />
              </Grid>
            ))}
            
          {(!success || !events.length) && (
            <Box sx={{ 
              gridColumn: '1 / -1',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              py: 4
            }}>
              <NoItemsMessage itemName="evento" gender="masculino" />
            </Box>
          )}
          
          {success && filteredEvents.map((event) => (
            <Grid key={event.id}>
              <EventCard event={event} sx={{ height: '100%' }} />
            </Grid>
          ))}
          
          {filteredEvents.length === 0 && searchTerm && (
            <Grid xs={12}>
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <Typography color="text.secondary">
                  Nenhum evento encontrado com o termo "{searchTerm}"
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Stack>
  );
}
