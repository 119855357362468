import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

const LoadingButtonStyled = styled(Button)(({ theme }) => ({
  position: 'relative',
}));

const ButtonProgressStyled = styled(CircularProgress)(({ theme }) => ({
  color: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

LoadingButtonStyled.propTypes = {
  loading: PropTypes.bool
};

export default function LoadingButton({ loading, ...props }) {

  return (
    <div>
      <LoadingButtonStyled disabled={loading || props.disabled} {...props}>
        {props.children}
      </LoadingButtonStyled>
      {loading && <ButtonProgressStyled size={24} />}
    </div>
  );
}
