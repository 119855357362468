import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Chip,
  Typography,
  useTheme,
} from "@mui/material";
import LinkButton from "../LinkButton";
import { APP_ROUTES } from '~/single-page/constants/routes';

const NGOCard = ({ ong }) => {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: { xs: "220px", md: "250px" },
        backgroundColor: "white",
        px: "18px",
        py: "21px",
        width: '100%',
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: theme.shadows[3],
          transform: 'translateY(-2px)'
        }
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
          p: 0,
          "&:last-child": { pb: 0 },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            flex: 1,
            mr: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bold",
            fontSize: { xs: "14px", md: "16px" },
            lineHeight: "22.4px",
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            whiteSpace: 'normal',
          }}
        >
          {ong.name}
        </Typography>
        <Chip
          sx={{ 
            minWidth: { xs: "103px" },
            flexShrink: 0,
            height: 'auto',
            '& .MuiChip-label': {
              whiteSpace: 'normal',
              padding: '4px 8px',
            }
          }}
          label={ong.is_formalized ? "Formalizada" : "Não Formalizada"}
          color="primary"
        />
      </CardContent>
      <CardContent
        sx={{
          flex: 1,
          p: 0,
          mt: 2,
          "&:last-child": { pb: 0 },
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: { xs: "11px", md: "12px" },
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            lineHeight: 1.5,
          }}
        >
          {ong.description}
        </Typography>
      </CardContent>
      <CardActions sx={{ 
        mt: "auto",
        px: 0,
        pb: 0
      }}>
        <LinkButton 
          size="large" 
          fullWidth 
          to={`${APP_ROUTES.NGO}/${ong.id}`}
        >
          Saiba mais
        </LinkButton>
      </CardActions>
    </Card>
  );
};

export default NGOCard;