import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Skeleton from "@mui/material/Skeleton";
import BloodBankCard from "~/components/BloodBank/Card";
import BloodBanksClient from "~/single-page/api/BloodBanksClient";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import NoItemsMessage from "~/components/NoItemsMessage";
import { useScrollToSection } from "~/single-page/hooks/useScrollToSection";

export default function BloodDonationsPage() {
  const {
    data: bloodBanks = [],
    pending,
    success,
  } = useApiCall({
    apiCall: BloodBanksClient.bloodBanks,
    init: true,
  });

  useScrollToSection();

  return (
    <Stack spacing={4} sx={{ maxWidth: '100%' }}>
      <Box>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2, mt: 2, textAlign: 'center' }}>
          Doação de sangue
        </Typography>
        <Typography variant="body1">
          Fazer o bem tem tudo a ver com salvar vidas! E doar sangue pode salvar
          várias ao mesmo tempo. Você pode fazer parte dessa ação de amor.
        </Typography>
      </Box>

      <Box>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
          Quem pode doar
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Doar sangue é um ato seguro e acessível para a maioria das pessoas
          saudáveis. Confira os principais requisitos para se tornar um doador e
          algumas condições específicas:
        </Typography>

        <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
          Requisitos gerais:
        </Typography>
        <Box component="ul" sx={{ mb: 3, pl: 2 }}>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Idade:</Typography> Ter entre 16 e 69 anos. Menores de 18 anos precisam de
            autorização dos responsáveis.
          </li>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Peso:</Typography> Pesar no mínimo 50 quilos.
          </li>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Saúde:</Typography> Estar em boas condições de saúde no dia da doação.
          </li>
        </Box>

        <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
          Condições temporárias que impedem a doação:
        </Typography>
        <Box component="ul" sx={{ mb: 3, pl: 2 }}>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Gripes ou resfriados:</Typography> Aguarde até estar totalmente
            recuperado.
          </li>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Vacinações:</Typography> Algumas vacinas exigem um período de espera para
            doação.
          </li>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Procedimentos recentes:</Typography> Aguarde 12 meses após realizar
            tatuagens, piercings ou cirurgias.
          </li>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Viagens internacionais:</Typography> Dependendo do destino, pode haver
            restrições temporárias.
          </li>
        </Box>

        <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
          Outras considerações importantes:
        </Typography>
        <Box component="ul" sx={{ mb: 3, pl: 2 }}>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Uso de medicamentos:</Typography> Consulte o hemocentro para verificar se
            os medicamentos em uso permitem a doação.
          </li>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Condições crônicas:</Typography> Portadores de doenças como diabetes ou
            hipertensão controladas podem doar, desde que apresentem
            estabilidade.
          </li>
          <li>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Gestantes e lactantes:</Typography> Não podem doar durante a gestação e
            devem aguardar pelo menos 12 meses após o parto.
          </li>
        </Box>
      </Box>

      <Box>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
          Bancos de sangue
        </Typography>
        <Box 
          id="blood-banks"
          sx={{ 
            scrollMarginTop: '190px',
            pt: 2
          }}
        >
          <Grid container spacing={4}>
            {pending &&
              Array.from(new Array(6)).map((_, index) => (
                <Grid xs={12} key={index}>
                  <Skeleton width="100%" variant="rectangular" height={118} />
                </Grid>
              ))}
            {(!success || !bloodBanks.length) && (
              <NoItemsMessage itemName="Banco de sangue" gender="masculino" />
            )}
            {bloodBanks.map((bloodBank, index) => (
              <Grid xs={12} key={index}>
                <BloodBankCard key={bloodBank.id} bloodBank={bloodBank} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Stack>
  );
}
