import * as React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";

const ROOT_PATH = "/site"; // Adjust this to match your root route.

export default function GoBackButton() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const showBackButton = location.pathname !== ROOT_PATH;

  if (!showBackButton) return null;

  return (
    <IconButton
      onClick={handleGoBack}
      aria-label="Go back"
      color="primary"
      sx={{ marginRight: 2 }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
}
