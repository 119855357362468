import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CharityIcon from "~/components/Icons/CharityIcon";
import BloodIcon from "~/components/Icons/BloodIcon";
import LightBulbIcon from "~/components/Icons/LightBulbIcon";
import RealHeartIcon from "~/components/Icons/RealHeartIcon";
import MonetizationIcon from "~/components/Icons/MonetizationIcon";
import OngIcon from "~/components/Icons/OngIcon";
import CalendarIcon from "~/components/Icons/CalendarIcon";

const IconMap = {
  charity: CharityIcon,
  blood: BloodIcon,
  lightbulb: LightBulbIcon,
  realheart: RealHeartIcon,
  monetization: MonetizationIcon,
  ong: OngIcon,
  calendar: CalendarIcon,
};

const NavigationItem = ({ segment, title, icon, isActive = false, onClick }) => {
  const Icon = IconMap[icon];

  const [path, hash] = segment.split('#');
  const to = hash ? `${path}#${hash}` : path || '/';

  const listItemStyles = {
    backgroundColor: "white",
    borderRadius: "4px",
    overflow: "hidden",
  };

  const listItemButtonStyles = {
    '& .MuiListItemIcon-root': {
      minWidth: '35px',
      '& svg': {
        fontSize: '24px'
      }
    },
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: { sm: '15px', lg: '16px' },
        lineHeight: { sm: '22px', lg: '24px' },
      }
    },
    borderRadius: "4px",
    py: { sm: 0.8, lg: 1 },
    px: { sm: 1.3, lg: 1.5 },
    ...(isActive && {
      bgcolor: "secondary.main",
      "& .MuiListItemText-root": {
        color: "#fff",
      },
      "& .MuiListItemIcon-root": {
        color: "#fff",
      }
    }),
    "&:hover, &:focus": {
      bgcolor: "secondary.main",
      "& svg:first-of-type": {
        transform: "translateX(-4px) rotate(-20deg)",
        transition: "transform 0.2s ease-in-out",
      },
      "& .MuiListItemText-root": {
        color: "#fff",
      },
      "& .MuiListItemIcon-root": {
        color: "#fff",
      }
    },
  };

  return (
    <ListItem
      disablePadding
      sx={listItemStyles}
    >
      <ListItemButton
        component={NavLink}
        to={to}
        sx={listItemButtonStyles}
        className={isActive ? "active" : ""}
        onClick={onClick}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

NavigationItem.propTypes = {
  segment: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOf([
    "charity",
    "blood",
    "lightbulb",
    "realheart",
    "monetization",
    "ong",
    "calendar"
  ]).isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NavigationItem;
