import axiosClient from "./axiosClient";
import ROUTES from "./api_routes";

async function events() {
  return axiosClient.get(ROUTES.GET_EVENTS);
}

async function event(id) {  
  return axiosClient.get(ROUTES.GET_EVENT(id));
}
    
const EventsClient = {
  events,
  event
};

export default EventsClient;

