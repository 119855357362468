import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { removeAccents } from '~/single-page/utils/removeAccents';

export default function SearchField({ 
  value, 
  onChange, 
  placeholder = "Pesquisar...",
  label = "Pesquisar",
  sx = {},
  fullWidth = true,
  ...props 
}) {
  const handleChange = (e) => {
    const normalizedValue = removeAccents(e.target.value);
    onChange({ ...e, target: { ...e.target, value: normalizedValue } });
  };

  return (
    <TextField 
      fullWidth={fullWidth}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      value={value}
      onChange={handleChange}
      sx={{ 
        mb: 4,
        maxWidth: 'auto',
        mx: { xs: 'auto', sm: 0 },
        display: 'block',
        ...sx
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
        ...props.InputProps
      }}
      {...props}
    />
  );
}
