import axiosClient from "./axiosClient";
import ROUTES from "./api_routes";

async function bloodBanks() {
  return axiosClient.get(ROUTES.GET_BLOOD_BANKS);
}

async function show(id) {
  return axiosClient.get(`${ROUTES.GET_BLOOD_BANKS}/${id}`);
}
const BloodBanksClient = {
  bloodBanks,
  show,
};

export default BloodBanksClient;
