import * as React from "react";
import { useRailsContext } from "~/single-page/contexts/RailsContext";
import NavBar from "~/components/NavBar";
import SideBar from "~/components/SideBar";
import Footer from "~/components/Footer";
import {
  Container,
  Box,
  Toolbar,
  Drawer,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToHashElement from "~/components/ScrollToHashElement";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = {
  default: 255,
  medium: 200,
};

export default function AppLayout() {
  const { flash } = useRailsContext();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:700px)');

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  React.useEffect(() => {
    if (flash?.notice) {
      toast.info(flash.notice);
    }
    if (flash?.alert) {
      toast.warning(flash.alert);
    }
  }, [flash]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <ScrollToHashElement />
      <NavBar toggleDrawer={handleDrawerToggle} />
      <Toolbar sx={{ minHeight: "89px !important" }} />
      
      <Box>
        <Box
          component="aside"
          sx={{ 
            width: { 
              xs: 0,
              sm: isMobile ? 0 : drawerWidth.medium,
              lg: drawerWidth.default 
            }, 
            flexShrink: { sm: 0 } 
          }}
          aria-label="bora impactar sidebar"
        >
          <Drawer
            sx={{
              display: { xs: "none", sm: isMobile ? "none" : "block" },
              width: {
                sm: drawerWidth.medium,
                lg: drawerWidth.default
              },
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: {
                  sm: drawerWidth.medium,
                  lg: drawerWidth.default
                },
                borderRight: "none",
                backgroundColor: (theme) => theme.palette.background.default,
                position: 'absolute',
                height: 'calc(100% - 125px)',
                top: '125px',
                left: {
                  sm: '35px',
                  md: '50px',
                  lg: '90px'
                },
                overflowY: 'visible',
                zIndex: 1,
              },
            }}
            variant="permanent"
            anchor="left"
            open
          >
            <SideBar drawerToggle={handleDrawerClose} />
          </Drawer>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: isMobile ? "block" : "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "100%",
                px: 3,
                pt: 2,
                position: 'relative',
              },
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              mb: 2
            }}>
              <IconButton 
                onClick={handleDrawerClose}
                sx={{ color: 'text.primary' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <SideBar drawerToggle={handleDrawerClose} />
          </Drawer>
        </Box>

        <Container
          component="main"
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pb: "100px",
            mx: "auto",
            position: 'relative',
            zIndex: 1,
            minHeight: '1300px',
            maxWidth: {
              xs: "100%",
              sm: isMobile ? "100%" : `calc(100% - ${drawerWidth.medium + 50}px)`,
              lg: `calc(100% - ${drawerWidth.default + 100}px)`
            },
            ml: { 
              xs: 0,
              sm: isMobile ? 0 : `${drawerWidth.medium + 50}px`,
              lg: `${drawerWidth.default + 100}px` 
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              p: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ToastContainer position="top-center" closeOnClick autoClose={8000} />
            <Box
              sx={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: { xs: "400px", sm: "600px", md: "832px" },
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
