import React, { useState } from 'react';
import {
  Avatar,
  TextField,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { toast } from 'react-toastify';
import AuthorizationClient from '../api/AuthorizationClient';
import LoadingButton from '../../components/LoadingButton.jsx';

const rdmcolor = '#D12E5E';

export default function ResetPassword() {
  const [user, setUser] = useState({
    password: '',
    password_confirmation: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const reset_password = (e) => {
    e.preventDefault();
    document.activeElement.blur();
    toast.dismiss();
    setLoading(true);
    AuthorizationClient.resetPassword(user)
      .then(() => {
        window.location.href = '/site';
      })
      .catch((response) => {
        if (response.status === 422) {
          setErrors(response.data.errors);
        } else {
          setErrors({});
        }
        toast.error(response.data?.error || 'Erro ao processar solicitação.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        mt: 3,
      }}
    >
      <Avatar sx={{ bgcolor: rdmcolor, mb: 1 }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Recuperar minha senha
      </Typography>
      <Box
        component="form"
        onSubmit={reset_password}
        noValidate
        sx={{ width: '100%', mt: 1 }}
      >
        {[
          { name: 'password', label: 'Senha', type: 'password', required: true },
          {
            name: 'password_confirmation',
            label: 'Confirmação da senha',
            type: 'password',
            required: true,
          },
        ].map((field) => (
          <TextField
            required={field.required}
            key={field.name}
            id={field.name}
            name={field.name}
            label={field.label}
            fullWidth
            autoComplete={field.name}
            value={user[field.name]}
            error={!!errors[field.name]}
            type={field.type}
            helperText={errors[field.name] ? errors[field.name].join(', ') : ''}
            onChange={(e) =>
              setUser({ ...user, [field.name]: e.target.value })
            }
            sx={{ mt: 2 }}
          />
        ))}

        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Redefinir senha
        </LoadingButton>
      </Box>
      <Grid container>
        <Grid item xs>
          <Typography
            component={Link}
            to="/siteublic/sign_in"
            variant="body2"
            sx={{ textDecoration: 'none' }}
          >
            Entrar
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            component={Link}
            to="/site/sign_up"
            variant="body2"
            sx={{ textDecoration: 'none' }}
          >
            Não possui uma conta? Cadastre-se
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
