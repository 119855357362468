import React from 'react'

export default function MonetizationIcon() {
  return (
    <>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2065_2679)">
          <path d="M12 2.57397C6.48 2.57397 2 7.05398 2 12.574C2 18.094 6.48 22.574 12 22.574C17.52 22.574 22 18.094 22 12.574C22 7.05398 17.52 2.57397 12 2.57397ZM13.41 18.664V19.244C13.41 19.974 12.81 20.574 12.08 20.574H12.07C11.34 20.574 10.74 19.974 10.74 19.244V18.644C9.41 18.364 8.23 17.634 7.73 16.404C7.5 15.854 7.93 15.244 8.53 15.244H8.77C9.14 15.244 9.44 15.494 9.58 15.844C9.87 16.594 10.63 17.114 12.09 17.114C14.05 17.114 14.49 16.134 14.49 15.524C14.49 14.694 14.05 13.914 11.82 13.384C9.34 12.784 7.64 11.764 7.64 9.71398C7.64 7.99398 9.03 6.87397 10.75 6.50397V5.90398C10.75 5.17397 11.35 4.57397 12.08 4.57397H12.09C12.82 4.57397 13.42 5.17397 13.42 5.90398V6.52397C14.8 6.86398 15.67 7.72398 16.05 8.78398C16.25 9.33398 15.83 9.91398 15.24 9.91398H14.98C14.61 9.91398 14.31 9.65398 14.21 9.29398C13.98 8.53398 13.35 8.04397 12.09 8.04397C10.59 8.04397 9.69 8.72398 9.69 9.68398C9.69 10.524 10.34 11.074 12.36 11.594C14.38 12.114 16.54 12.984 16.54 15.504C16.52 17.334 15.15 18.334 13.41 18.664Z" fill="#009FE3" />
        </g>
        <defs>
          <clipPath id="clip0_2065_2679">
            <rect width="24" height="24" fill="white" transform="translate(0 0.573975)" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
