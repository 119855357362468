import React, { useEffect } from 'react';
import { Stack, Box, Typography, Skeleton, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import KnowledgeTrailsSlider from "~/components/KnowledgeTrails/Slider";
import KnowledgeTrailsClient from "~/single-page/api/KnowledgeTrailsClient";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import NoItemsMessage from "~/components/NoItemsMessage";
import ActiveNoticeSlider from "~/components/ActiveNotices/Slider";
import { useScrollToSection } from '~/single-page/hooks/useScrollToSection';
import caixaEconomicaImage from '~/images/caixa-economica-logo.png';
import receitaFederalImage from '~/images/receita-federal-logo.png';

const LoadingSkeleton = () => (
  Array.from({ length: 3 }).map((_, index) => (
    <Grid container spacing={4} key={index} sx={{ mb: 4 }}>
      <Grid xs={4}>
        <Skeleton width="100%" height={50} />
      </Grid>
      <Grid xs={8}>
        <Skeleton width="100%" height={200} />
      </Grid>
    </Grid>
  ))
);

export default function KnowledgeTrailsPage() {
  const {
    data: knowledgeTrails = [],
    pending,
    success,
  } = useApiCall({
    apiCall: KnowledgeTrailsClient.knowledgeTrails,
    init: true,
  });

  useScrollToSection();

  if (pending) return <LoadingSkeleton />;

  if (!success || knowledgeTrails.length === 0) {
    return (
      <Box sx={{ margin: '2rem 0' }}>
        <NoItemsMessage itemName="Trilha de capacitação" gender="feminino" />
      </Box>
    );
  }

  const TrailItem = ({ trail }) => (
    <Grid 
      container 
      spacing={0}
      key={trail.id} 
      sx={{ 
        mb: 4,
        pb: 2,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        width: '100%',
        flexDirection: { xs: 'column', md: 'row' }
      }}
    >
      <Grid 
        xs={12} 
        md={2}
        lg={2}
        sx={{
          mb: { xs: 1, md: 0 },
          pr: { md: 2 },
          width: { md: '200px' },
          flexShrink: 0
        }}
      >
        <Box
          sx={{
            px: { xs: 1, md: 2 },
            pt: { xs: 0.5, md: 1 },
            pb: { xs: 0.5, md: 1 },
            bgcolor: 'grey.100',
            borderRadius: 1,
            minHeight: { xs: 'auto', md: '130px' },
            display: 'flex',
            flexDirection: { xs: 'row', md: 'column' },
            justifyContent: 'space-between',
            alignItems: { xs: 'center', md: 'flex-start' }
          }}
        >
          <Typography 
            variant="subtitle1"
            sx={{ 
              fontWeight: 'bold',
              lineHeight: 1.2,
              wordBreak: 'break-word',
              pl: { xs: 0.5, md: 1 },
              flex: { xs: 1, md: 'none' },
              mb: { md: 2 },
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: { xs: 2, md: 4 },
              WebkitBoxOrient: 'vertical'
            }}
          >
            {trail.name}
          </Typography>
        </Box>
      </Grid>

      <Grid 
        xs={12} 
        md={10}
        lg={10}
        sx={{ 
          height: { xs: '180px', md: '130px' },
          display: 'flex',
          alignItems: 'center',
          flex: 1
        }}
      >
        <Box sx={{ 
          width: '100%', 
          height: '100%', 
          margin: 0, 
          padding: 0,
          display: 'flex',
          alignItems: 'center'
        }}>
          {console.log(trail.courses)}
          <KnowledgeTrailsSlider 
            courses={trail.courses || []} 
            type={trail.type}
          />
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Stack spacing={4} sx={{ maxWidth: '100%' }}>
      <Box>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2, mt: 2, textAlign: 'center' }}>
          Jornada para o terceiro setor
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 2 }}>
          Se você tem o desejo de transformar sua causa em um movimento formal,
          garantir mais credibilidade e atrair recursos e parcerias, a
          formalização de sua organização não governamental (ONG) é um passo
          essencial. E ao seguir a trilha de capacitação oferecida nesta página,
          você vai aprender como tornar esse processo mais simples e eficiente,
          adquirindo as habilidades necessárias para estruturar sua organização e
          potencializar seu impacto.
        </Typography>

        <Typography variant="body1" sx={{ mb: 3 }}>
          A formalização de uma ONG não precisa ser um desafio burocrático. Com o
          conhecimento certo, você pode atravessar essa etapa de forma descomplicada
          e ainda aproveitar as vantagens de uma gestão organizada e estratégica.
          Vamos entender, passo a passo, como você pode se formalizar e como a
          capacitação pode te ajudar nesse processo.
        </Typography>

        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center' }}>
          Capacitações
        </Typography>
      </Box>

      {knowledgeTrails.map(trail => (
        <TrailItem key={trail.id} trail={trail} />
      ))}

      <Typography 
        id="active-notices"
        variant="h5" 
        component="h2" 
        sx={{ 
          fontWeight: 'bold', 
          mb: 3, 
          textAlign: 'center',
          scrollMarginTop: '80px'
        }}
      >
        Editais ativos
      </Typography>
      <div style={{ width: "100%" }}>
        <ActiveNoticeSlider />
      </div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 4,
          mb: 4,
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            maxWidth: '395px'
          }}
        >
          <Typography
            variant="h6"
            component="h3"
            sx={{
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            Doação de Bens Móveis
          </Typography>
          <Box
            component="a"
            href="https://www.caixa.gov.br/doacao-de-bens-moveis/Paginas/default.aspx"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              width: '100%',
              maxWidth: '295px',
              height: '99px',
              position: 'relative',
              '&:hover': {
                opacity: 0.9
              }
            }}
          >
            <Box
              component="img"
              src={caixaEconomicaImage}
              alt="Doação de Bens Móveis"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                borderRadius: 1
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            maxWidth: '395px'
          }}
        >
          <Typography
            variant="h6"
            component="h3"
            sx={{
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            Doações de Mercadorias Apreendidas
          </Typography>
          <Box
            component="a"
            href="https://www.gov.br/receitafederal/pt-br/assuntos/leilao/pdm/doacoes"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              width: '100%',
              maxWidth: '295px',
              height: '99px',
              position: 'relative',
              '&:hover': {
                opacity: 0.9
              }
            }}
          >
            <Box
              component="img"
              src={receitaFederalImage}
              alt="Doações de Mercadorias Apreendidas"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                borderRadius: 1
              }}
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
