import { useState } from 'react';
import DonationModal from '~/components/NGOs/DonationModal';

export const useDonationModal = () => {
  const [isDonationModalOpen, setIsDonationModalOpen] = useState(false);

  const handleOpenDonationModal = () => setIsDonationModalOpen(true);
  const handleCloseDonationModal = () => setIsDonationModalOpen(false);

  const DonationModalComponent = ({ ngo }) => {
    if (!ngo) return null;
    
    return (
      <DonationModal 
        open={isDonationModalOpen} 
        onClose={handleCloseDonationModal} 
        ngo={ngo} 
      />
    );
  };

  return {
    isDonationModalOpen,
    handleOpenDonationModal,
    handleCloseDonationModal,
    DonationModalComponent
  };
};
