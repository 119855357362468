import axiosClient from "./axiosClient";
import ROUTES from "./api_routes";

async function knowledgeTrails() {
  return axiosClient.get(ROUTES.GET_KNOWLEDGE_TRAILS);
}

const KnowledgeTrailsClient = {
  knowledgeTrails,
};

export default KnowledgeTrailsClient;
