import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { ThemeContext } from '~/single-page/contexts/ThemeContext';

export const ThemeProvider = ({ children }) => {
    const [mode, setMode] = useState('light');

    const toggleColorMode = () => {
      setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    const theme = useMemo(
      () =>
        createTheme({
          palette: {
            mode,
            primary: {
                main: "#009FE3",
                contrastText: '#fff',
            },
            secondary: {
              main: "#294BB6", // customize your secondary color
              contrastText: '#fff',
            },
            // You can add custom colors
            custom: {
              main: '#f50057',
            },
            // Customize background and text colors for both modes
            ...(mode === 'light'
              ? {
                  background: {
                    default: '#f5f5f5',
                    paper: '#ffffff',
                  },
                }
              : {
                  background: {
                    default: '#121212',
                    paper: '#1e1e1e',
                  },
                  text: {
                    primary: '#fff',
                    secondary: 'rgba(255, 255, 255, 0.7)',
                  },
                }),
          },
          // You can also customize typography, spacing, breakpoints, etc.
          typography: {
            fontFamily: ["'DM Sans'", "serif"].join(","),
          },
        }),
      [mode]
    );

    const themeContextValue = useMemo(
      () => ({
        mode,
        toggleColorMode,
      }),
      [mode]
    );

    return (
      <ThemeContext.Provider value={themeContextValue}>
        <MUIThemeProvider theme={theme}>
          {children}
        </MUIThemeProvider>
      </ThemeContext.Provider>
    );
};
