import React from 'react'

export default function RealHeartIcon() {
  return (
    <>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.58186 13L9 12.3925L10.1379 11.2043C10.2702 11.0655 10.3522 10.8831 10.3697 10.6881C10.592 8.02608 12.733 5.98715 15.2925 6.00006H17.7642C18.4466 6.00006 19 6.5778 19 7.29036V8.15051C19 8.86323 18.4466 9.44081 17.7642 9.44081H16.5283C14.9369 9.4438 13.6475 10.79 13.6447 12.4516H12.8208C12.8235 10.315 14.4819 8.58365 16.5283 8.58066H17.7642C17.9917 8.58066 18.176 8.38803 18.176 8.15051V7.29036C18.176 7.05284 17.9917 6.86021 17.7642 6.86021H15.2925C13.1586 6.84903 11.3733 8.54916 11.1884 10.7688C11.1528 11.1654 10.985 11.5363 10.7147 11.8171L9.58186 13Z" fill="#009FE3" stroke="#009FE3" strokeWidth="0.5" />
        <path d="M10.2965 11C8.53729 9.61825 8.15761 7.43742 9.37519 5.71092L8 4.58941L8.72283 4L10.693 5.60654L10.45 5.89353C9.25975 7.30203 9.49232 9.20565 11 10.395L10.2965 11Z" fill="#009FE3" stroke="#009FE3" strokeWidth="0.5" />
        <path d="M12.0783 5L10 2.98643L10.6809 2.32676L12.3675 3.96107C12.5876 3.9149 12.8112 3.88567 13.0362 3.87353V2H14V4.84235L13.488 4.8073C13.1043 4.7837 12.7194 4.8232 12.3493 4.92408L12.0783 5Z" fill="#009FE3" stroke="#009FE3" strokeWidth="0.5" />
        <path d="M16.0994 7C15.8756 6.53701 15.559 6.12126 15.1678 5.777L15 5.63913V2H15.9937V5.21952C16.4095 5.617 16.75 6.08174 17 6.59233L16.0994 7Z" fill="#009FE3" stroke="#009FE3" strokeWidth="0.5" />
        <path d="M11.9999 23C9.23605 23 5 18.205 5 12.2949C5 9.47929 6.85806 6.9479 9.62721 6L9.87421 6.85316C7.44992 7.68394 5.82348 9.86952 5.82348 12.2949C5.82348 17.9653 9.88461 22.108 11.9999 22.108C14.1154 22.108 18.1764 17.9653 18.1764 12.2949C18.1764 11.1129 17.8007 9.97554 17.0852 9.00526L17.7287 8.44775C18.5624 9.57958 19 10.9066 19 12.2949C19 18.205 14.7639 23 11.9999 23Z" fill="#009FE3" stroke="#009FE3" strokeWidth="0.5" />
      </svg>
    </>
  )
}
