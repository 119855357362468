import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import Skeleton from "@mui/material/Skeleton";
import ActiveNoticeCard from "./SlideCard";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import ActiveNoticesClient from "~/single-page/api/ActiveNoticesClient";
import NoItemsMessage from "../NoItemsMessage";

export default function ActiveNoticeSlider() {  
  const {
    data: activeNotices = [],
    success,
    pending,
  } = useApiCall({
    apiCall: () => ActiveNoticesClient.activeNotices(),
    init: true,
  });

  if (pending) {
    return (
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        className="active-notices-swiper"
        spaceBetween={8}
        slidesPerView={1}
        breakpoints={{
          900: {
            slidesPerView: 3,
            spaceBetween: 8,
          },
        }}
      >
        {Array.from({ length: 6 }).map((_, index) => (
          <SwiperSlide key={index}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              animation="wave"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }

  if (!success || !activeNotices.length) {
    return <NoItemsMessage itemName="Edital" gender="feminino" />;
  }

  return (
    <Swiper
      modules={[Autoplay]}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      className="active-notices-swiper"
      spaceBetween={8}
      slidesPerView={1}
      breakpoints={{
        900: {
          slidesPerView: 3,
          spaceBetween: 8,
        },
      }}
    >
      {activeNotices.map((activeNotice, index) => (
        <SwiperSlide key={index}>
          <ActiveNoticeCard 
            key={activeNotice.name} 
            activeNotice={activeNotice} 
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
