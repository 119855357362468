import React from "react";
import OngsClient from "../api/OngsClient";
import Grid from "@mui/material/Grid2";
import Skeleton from "@mui/material/Skeleton";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import { useParams } from 'react-router-dom';
import NoItemsMessage from "~/components/NoItemsMessage";
import { Stack, Box, Typography, Button, Link } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import ActionsSlider from "~/components/ActionManagement/Slider";
import LinkButton from "~/components/LinkButton";
import ODSsCards from "~/components/ODSs/Cards";
import GalleryCards from "~/components/NGOs/GalleryCards";
import SocialMediaLinks from '~/components/NGOs/SocialMediaLinks';
import { useDonationModal } from '~/single-page/hooks/useDonationModal';
import { useVolunteerModal } from '~/single-page/hooks/useVolunteerModal';
import { APP_ROUTES } from "~/single-page/constants/routes";
import { useScrollToSection } from '~/single-page/hooks/useScrollToSection';

const LoadingSkeleton = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Skeleton variant="rectangular" height={118} />
    </Grid>
  </Grid>
);

const ActionButton = ({ variant, onClick, children, outlined }) => (
  <Button
    variant={variant}
    onClick={onClick}
    sx={{
      backgroundColor: outlined ? 'transparent' : '#294BB6',
      color: outlined ? '#294BB6' : 'white',
      borderColor: '#294BB6',
      '&:hover': {
        backgroundColor: outlined ? 'transparent' : '#294BB6',
        borderColor: '#294BB6',
        opacity: 0.8
      },
      width: { xs: '100%', sm: '270px' },
      px: 4,
      py: 1.5,
      textTransform: 'none',
      fontWeight: outlined ? 600 : 700
    }}
  >
    {children}
  </Button>
);

export default function NGODetailPage() {
  const { id } = useParams();
  useScrollToSection();

  const {
    data: ong = null,
    success,
    pending,
  } = useApiCall({
    apiCall: () => id ? OngsClient.ong(id) : Promise.reject('ID não fornecido'),
    init: true,
    deps: [id],
  });

  const { handleOpenDonationModal, DonationModalComponent } = useDonationModal();
  const { handleOpenVolunteerModal, VolunteerModalComponent } = useVolunteerModal();

  if (pending) return <LoadingSkeleton />;
  if (!success || !ong) return <NoItemsMessage itemName="ONG" gender="feminino" />;

  return (
    <Stack spacing={4} sx={{ maxWidth: '100%', px: 2, py: 3 }}>
      <Box>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            mb: 2 
          }}
        >
          <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
            {ong.name}
          </Typography>
          <Button
            endIcon={<ShareIcon />}
            color="primary"
            size="small"
            onClick={() => {}}
          >
            Compartilhar
          </Button>
        </Box>

        <Typography variant="subtitle1" color="text.secondary">
          Desde {ong.start_year}
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Conheça mais sobre {ong.name.toLowerCase().startsWith('a') ? 'a' : 'o'} {ong.name}
        </Typography>

        <Typography variant="body1" sx={{ mb: 4 }}>
          {ong.description}
        </Typography>
      </Box>
      
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          justifyContent: { xs: 'center', sm: 'flex-start' },
          flexDirection: { xs: 'column', sm: 'row' },
          width: { xs: '100%', sm: 'auto' }
        }}
      >
        <ActionButton variant="contained" onClick={handleOpenDonationModal}>
          Quero doar
        </ActionButton>

        <ActionButton variant="outlined" outlined onClick={handleOpenVolunteerModal}>
          Quero ser um voluntário
        </ActionButton>
      </Box>
      
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Ações
        </Typography>
        <Box sx={{ width: "100%" }}>
          <ActionsSlider ong={ong} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <LinkButton
            color="secondary"
            sx={{ width: "255px" }}
            size="large"
            to={APP_ROUTES.ACTION_MANAGEMENTS}
          >
            Ver todas
          </LinkButton>
        </Box>
      </Box>

      <Box>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Typography variant="h6">
            Objetivos para o Desenvolvimento Sustentável
          </Typography>
          <Link
            href="https://brasil.un.org/pt-br/sdgs"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ 
              textDecoration: 'none',
              color: 'primary.main',
              fontSize: '0.875rem',
              '&:hover': { textDecoration: 'underline' }
            }}
          >
            Saiba mais
          </Link>
        </Box>
        <ODSsCards odss={ong.sustainable_development_goals}/>
      </Box>

      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Galeria
        </Typography>
        <GalleryCards ong={ong} />
      </Box>

      {(ong.instagram_link || ong.facebook_link || ong.x_link) && (
        <Box 
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 3,
            flexWrap: { xs: 'wrap', sm: 'nowrap' }
          }}
        >
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 500,
              color: 'text.primary',
              textAlign: { xs: 'center', sm: 'left' }
            }}
          >
            Acompanhe nossas redes sociais
          </Typography>
          <SocialMediaLinks 
            socialLinks={{
              instagram: ong.instagram_link,
              facebook: ong.facebook_link,
              x: ong.x_link
            }} 
          />
        </Box>
      )}

      <DonationModalComponent ngo={ong} />
      <VolunteerModalComponent ngo={ong} />
    </Stack>
  );
}
