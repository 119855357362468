import axiosClient from "./axiosClient";
import ROUTES from "./api_routes";

async function ongs() {
  return axiosClient.get(ROUTES.GET_ONGS);
}

async function ong(id) {
  return axiosClient.get(ROUTES.GET_ONG(id));
}

async function locations() {
  return axiosClient.get(`${ROUTES.GET_LOCATIONS}`);
}

async function cause_ngos(id) {
  return axiosClient.get(ROUTES.GET_CAUSE_NGOS(id));
}

const OngsClient = {
  ongs,
  ong,
  locations,
  cause_ngos
};

export default OngsClient;
