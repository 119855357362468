import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

/**
 * A reusable LinkButton component that combines MUI Button and React Router's Link.
 *
 * @param {object} props - Props for the component.
 * @param {string} props.to - The target route for navigation.
 * @param {React.ReactNode} props.children - Content of the button.
 * @param {string} [props.variant='contained'] - MUI Button variant (e.g., 'text', 'outlined', 'contained').
 * @param {string} [props.color='primary'] - MUI Button color (e.g., 'default', 'primary', 'secondary').
 * @param {string} [props.size='medium'] - MUI Button size (e.g., 'small', 'medium', 'large').
 * @param {object} props.style - Additional inline styles for the button.
 * @param {object} props.linkProps - Additional props for the Link component.
 * @returns {JSX.Element} The LinkButton component.
 */
const LinkButton = ({
  to,
  children,
  variant = "contained",
  color = "primary",
  size = "medium",
  sx,
  linkProps = {},
  ...buttonProps
}) => {
  return (
    <Button
      component={Link}
      to={to}
      variant={variant}
      color={color}
      size={size}
      sx={{ ...sx, textTransform: "none", fontWeight: 700 }}
      {...linkProps}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired, // Target route
  children: PropTypes.node.isRequired, // Button content
  variant: PropTypes.oneOf(["text", "outlined", "contained"]), // MUI Button variant
  color: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
    "inherit"
  ]), // MUI color
  size: PropTypes.oneOf(["small", "medium", "large"]), // MUI Button size
  sx: PropTypes.object, // Custom sx props
  linkProps: PropTypes.object, // Additional Link props
};

export default LinkButton;
