import React from "react";
import PropTypes from "prop-types";
import { 
  Divider,
  List,
  ListSubheader,
  Box,
  IconButton,
  useMediaQuery,
  Typography,
  Collapse,
} from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NAVIGATION } from "~/single-page/constants/navigation";
import NavigationItem from "./NavigationItem";
import boraImpactar from "~/images/illustrations/bora-impactar.svg";
import { useLocation, Link } from "react-router-dom";

const SideBar = ({ drawerToggle }) => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:700px)');
  const [expandedSections, setExpandedSections] = React.useState({});
  
  const toggleSection = (title) => {
    setExpandedSections(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  const getSectionItems = (startIndex) => {
    const items = [];
    for (let i = startIndex + 1; i < NAVIGATION.length; i++) {
      if (NAVIGATION[i].kind === "header") break;
      items.push(NAVIGATION[i]);
    }
    return items;
  };

  const handleNavigationClick = () => {
    if (isMobile && drawerToggle) {
      drawerToggle();
    }
  };
  
  return (
    <Box sx={{ 
      height: 'auto',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: { xs: 'auto', sm: 'visible' },
      '& .MuiDrawer-paper': {
        position: 'static',
        height: 'auto',
        minHeight: '100%'
      }
    }}>
      <Box sx={{ 
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Link to="/" style={{ textDecoration: 'none' }} onClick={handleNavigationClick}>
          <img 
            src={boraImpactar} 
            alt="Logo do Bora Impactar"
            style={{
              maxWidth: isMobile ? '150px' : '100%',
              height: 'auto',
            }} 
          />
        </Link>
        {isMobile && (
          <IconButton 
            onClick={drawerToggle}
            sx={{ color: 'primary.main' }}
          >
          </IconButton>
        )}
      </Box>

      <List sx={{ 
        flex: 1,
        pt: 0,
        '& .MuiListItem-root': {
          transition: 'background-color 0.2s ease',
          '&:hover': {
            backgroundColor: 'rgba(41, 75, 182, 0.04)',
          },
          py: { xs: 1.5, sm: 0.5 }
        },
        '& .MuiDivider-root': {
          my: 0
        }
      }}>
        {NAVIGATION.map((item, index) => {
          if (item.kind === "header") {
            const isExpanded = expandedSections[item.title] !== false;
            const sectionItems = getSectionItems(index);
            
            return (
              <Box key={item.title} sx={{ 
                mt: 1,
                '&:first-of-type': {
                  mt: 0
                }
              }}>
                <ListSubheader
                  component="div"
                  onClick={() => isMobile && toggleSection(item.title)}
                  sx={{
                    color: "#000",
                    bgcolor: "transparent",
                    fontSize: { xs: "16px", sm: "18px", lg: "20px" },
                    lineHeight: { xs: "20px", sm: "22px", lg: "24px" },
                    fontWeight: "bold",
                    py: 1.5,
                    px: 0,
                    cursor: isMobile ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    '&:hover': {
                      backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.04)' : 'transparent'
                    }
                  }}
                >
                  <Typography 
                    variant="inherit" 
                    sx={{ 
                      flex: 1,
                      textAlign: 'left',
                      width: '100%'
                    }}
                  >
                    {item.title}
                  </Typography>
                  {isMobile && (
                    <IconButton size="small" sx={{ ml: 1 }}>
                      {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )}
                </ListSubheader>
                <Collapse in={!isMobile || isExpanded}>
                  <Box sx={{ 
                    '& > *': {
                    }
                  }}>
                    {sectionItems.map((subItem, subIndex, array) => {
                      if (subItem.kind === "divider") {
                        return subIndex < array.length - 1 ? (
                          <Divider
                            key={`${index}-${subIndex}`}
                            sx={{ 
                              borderColor: "rgba(219, 219, 219, 1)",
                              my: 0
                            }}
                          />
                        ) : null;
                      }

                      const isActive = (() => {
                        if (subItem.segment.startsWith('#')) {
                          return location.pathname === '/' && location.hash === subItem.segment;
                        }

                        if (location.hash) {
                          if (subItem.segment.includes('#')) {
                            const [path, hash] = subItem.segment.split('#');
                            return location.pathname === path && location.hash === `#${hash}`;
                          }
                          return false;
                        }

                        if (subItem.segment.includes('#')) {
                          return false;
                        }
                        
                        return location.pathname === subItem.segment;
                      })();

                      return (
                        <NavigationItem 
                          key={subItem.segment} 
                          {...subItem} 
                          isActive={isActive}
                          onClick={handleNavigationClick}
                        />
                      );
                    })}
                  </Box>
                </Collapse>
              </Box>
            );
          }
          return null;
        })}
      </List>
    </Box>
  );
};

SideBar.propTypes = {
  drawerToggle: PropTypes.func.isRequired,
};

export default SideBar;
