import React, { useState } from "react";
import { Stack, Box, Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Skeleton from "@mui/material/Skeleton";
import ActionCard from "~/components/ActionManagement/Card";
import ActionManagementsClient from "../api/ActionManagementsClient";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import NoItemsMessage from "~/components/NoItemsMessage";
import SearchField from '~/components/SearchField';
import { useScrollToSection } from '~/single-page/hooks/useScrollToSection';

const LoadingSkeleton = () => (
  <Grid container spacing={2}>
    {Array.from(new Array(6)).map((_, index) => (
      <Grid xs={12} sm={12} md={6} key={index}>
        <Skeleton variant="rectangular" height={118} />
      </Grid>
    ))}
  </Grid>
);

export default function ActionManagementsPage() {
  const {
    data: actions = [],
    success,
    pending,
  } = useApiCall({
    apiCall: ActionManagementsClient.actionManagements,
    init: true,
  });

  const [searchTerm, setSearchTerm] = useState('');
  useScrollToSection();

  const filteredActions = actions.filter(action => {
    return action.name.toLowerCase().includes(searchTerm) || 
           (action.short_description || '').toLowerCase().includes(searchTerm) ||
           (action.ong?.name || '').toLowerCase().includes(searchTerm);
  });

  if (pending) {
    return <LoadingSkeleton />;
  }

  if (!success || !actions.length) {
    return (
      <Box sx={{ margin: '2rem 0' }}>
        <NoItemsMessage itemName="ação" gender="feminino" />
      </Box>
    );
  }

  return (
    <Stack spacing={4} sx={{ maxWidth: '100%' }}>
      <Box>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2, mt: 2, textAlign: 'center' }}>
          Ações em movimento
        </Typography>
        
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 4, 
            textAlign: "center" 
          }}
        >
          Aqui é a área do engajamento. Conheça as ações realizadas por ONGs e
          movimentos sociais parceiros do <Box component="b">Bora Impactar</Box> e escolha uma para
          fazer parte. Entre em campo e ajude a fazer a diferença. Nesse jogo,
          todo mundo ganha!
        </Typography>
      </Box>

      <SearchField 
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        label="Pesquisar ações..."
        placeholder="Digite o nome da ação, descrição, ONG ou projeto"
      />

      <Divider sx={{ width: "100%" }} />

      <Box>
        <Grid 
          container 
          spacing={2}
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              sm: '1fr',
              md: 'repeat(2, 1fr)'
            },
            gap: 2
          }}
        >
          {filteredActions.map((action, index) => (
            <Grid 
              key={index}
              sx={{ 
                width: '100%',
                height: '100%'
              }}
            >
              <ActionCard 
                action={action} 
                key={action.name} 
                sx={{ height: '100%' }}
              />
            </Grid>
          ))}
        </Grid>

        {filteredActions.length === 0 && searchTerm && (
          <Box sx={{ 
            width: '100%', 
            textAlign: 'center', 
            py: 4 
          }}>
            <Typography color="text.secondary">
              Nenhuma ação encontrada com o termo "{searchTerm}"
            </Typography>
          </Box>
        )}
      </Box>
    </Stack>
  );
}
