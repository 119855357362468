import React from 'react';
import { Stack, Box, TextField, Divider, Typography } from "@mui/material";
import LinkButton from "~/components/LinkButton";
import ActionsSlider from "~/components/ActionManagement/Slider";
import descubraSuaCausa from "~/images/descubra-sua-causa.png";
import NGOsMap from "~/components/NGOs/Map";
import CauseGrid from "~/components/Cause/Grid";
import { APP_ROUTES } from "~/single-page/constants/routes";
import { useScrollToSection } from '~/single-page/hooks/useScrollToSection';

export default function OpportunitiesPage() {
  useScrollToSection();

  return (
    <Stack spacing={4} sx={{ maxWidth: '100%' }}>
      <Box>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2, mt: 2, textAlign: 'center' }}>
          Somar para multiplicar
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 3 }}>
          <strong>Bora Impactar, minha gente!</strong> Esta é uma plataforma
          digital totalmente dedicada para gerar conexões. Tudo aqui é feito para
          multiplicar as possibilidades de praticar boas ações com os olhos
          voltados para quem mais precisa. Neste espaço você é nosso parceiro para
          fortalecer e ampliar o trabalho realizado pelos movimentos sociais e
          ONGs.
        </Typography>

        <Typography variant="body1" sx={{ mb: 4 }}>
          <strong>No Bora Impactar</strong> abrimos diversas portas e janelas
          conectando quem precisa de ajuda e quem busca por apoio. Duas pontas
          unidas que juntas somam um Recife ainda mais forte. Porque fazer o bem é
          via de mão dupla: ganha quem recebe, mas ganha também quem oferece
          ajuda.
        </Typography>

        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
          Oportunidades
        </Typography>
      </Box>

      <TextField 
        fullWidth 
        label="Pesquisar..." 
        id="fullWidth" 
      />

      <Divider sx={{ width: "100%" }} />

      <Box id="opportunities" sx={{ scrollMarginTop: '80px' }}>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
          Ações em movimento
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
          Fique por dentro! Confira as ações que estão rolando na cidade. Uma
          delas pode se encaixar no seu perfil e você pode impactar a vida de
          muita gente!
        </Typography>

        <Box sx={{ width: "100%", mb: 4 }}>
          <ActionsSlider />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <LinkButton
            color="secondary"
            sx={{ width: "255px" }}
            size="large"
            to={`${APP_ROUTES.ACTION_MANAGEMENTS}`}
          >
            Ver todas
          </LinkButton>
        </Box>
      </Box>

      <Box>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
          Bora Impactar: Conheça nossos parceiros
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
          Conheça mais sobre as ONGs e os projetos que fazem parte da nossa
          conexão.
        </Typography>

        <CauseGrid />
      </Box>

      <Stack spacing={0} sx={{ width: '100%' }}>
        <Box
          id="find-your-cause"
          sx={{
            width: '100%',
            height: { xs: "174px", md: "auto" },
            display: "flex",
            justifyContent: "center",
            py: 12,
            scrollMarginTop: '80px'
          }}
        >
          <a 
            href="https://descubrasuacausa.net.br/" 
            target="_blank"
            rel="noopener noreferrer"
            style={{ 
              width: '100%', 
              maxWidth: '825px',
              display: 'block'
            }}
          >
            <img
              src={descubraSuaCausa}
              alt="descubra sua causa"
              style={{
                width: '100%',
                height: 'auto',
                display: 'block'
              }}
            />
          </a>
        </Box>

        <Box 
          sx={{ 
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: { 
              xs: 20,
              sm: 20,
              md: 0
            }
          }}
        >
          <NGOsMap />
        </Box>
      </Stack>
    </Stack>
  );
}
