import React from 'react'

export default function CalendarIcon() {
  return (
    <>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2065_2771)">
          <path d="M20 3.07397H19V2.07397C19 1.52397 18.55 1.07397 18 1.07397C17.45 1.07397 17 1.52397 17 2.07397V3.07397H7V2.07397C7 1.52397 6.55 1.07397 6 1.07397C5.45 1.07397 5 1.52397 5 2.07397V3.07397H4C2.9 3.07397 2 3.97397 2 5.07397V21.074C2 22.174 2.9 23.074 4 23.074H20C21.1 23.074 22 22.174 22 21.074V5.07397C22 3.97397 21.1 3.07397 20 3.07397ZM19 21.074H5C4.45 21.074 4 20.624 4 20.074V8.07397H20V20.074C20 20.624 19.55 21.074 19 21.074Z" fill="#009FE3" />
        </g>
        <defs>
          <clipPath id="clip0_2065_2771">
            <rect width="24" height="24" fill="white" transform="translate(0 0.0739746)" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
