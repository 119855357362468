import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import EventIcon from '@mui/icons-material/Event';
import LinkButton from "../LinkButton";
import { APP_ROUTES } from '~/single-page/constants/routes';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const EventCard = ({ event }) => {
  const theme = useTheme();

  const formatEventDate = (dateString) => {
    const date = new Date(dateString);
    const weekDay = format(date, 'EEE', { locale: ptBR }).toUpperCase();
    const day = format(date, 'dd');
    const month = format(date, 'MMM', { locale: ptBR }).toUpperCase();
    
    if (event.all_day) {
      return `${weekDay}, ${day} ${month}`;
    }
    
    const time = format(date, 'HH:mm');
    return `${weekDay}, ${day} ${month} • ${time}`;
  };

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: '380px',
        backgroundColor: "white",
        width: '100%',
        borderRadius: 2,
        transition: 'all 0.3s ease',
        overflow: 'hidden',
        '&:hover': {
          boxShadow: theme.shadows[3],
          transform: 'translateY(-2px)'
        }
      }}
    >
      {event.card_image_url ? (
        <CardMedia
          component="img"
          height="200"
          image={event.card_image_url}
          alt={event.title}
          sx={{
            objectFit: 'cover'
          }}
        />
      ) : (
        <Box
          sx={{
            height: 200,
            backgroundColor: 'grey.100',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1
          }}
        >
          <EventIcon 
            sx={{ 
              fontSize: 60,
              color: 'grey.400'
            }} 
          />
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary',
              fontSize: '0.875rem'
            }}
          >
            Imagem não disponível
          </Typography>
        </Box>
      )}
      
      <CardContent
        sx={{
          flex: 1,
          p: 2,
          "&:last-child": { pb: 2 },
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: { xs: "12px", md: "13px" },
            mb: 1,
            fontWeight: 500
          }}
        >
          {formatEventDate(event.start_date)}
        </Typography>

        <Typography
          variant="h5"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bold",
            fontSize: '17px',
            lineHeight: 1.4,
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            whiteSpace: 'normal',
            mb: 1
          }}
        >
          {event.title}
        </Typography>
      </CardContent>

      <CardActions sx={{ 
        p: 2,
        pt: 0
      }}>
        <LinkButton 
          size="large" 
          fullWidth 
          to={`${APP_ROUTES.EVENT}/${event.id}`}
        >
          Saiba mais
        </LinkButton>
      </CardActions>
    </Card>
  );
};

export default EventCard;