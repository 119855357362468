import { APP_ROUTES } from "~/single-page/constants/routes";

export const NAVIGATION = [
  {
    kind: "header",
    title: "Voluntariado e doação",
  },
  {
    segment: APP_ROUTES.ROOT + "#oportunidades",
    title: "Oportunidades",
    icon: "charity",
  },
  {
    kind: "divider",
  },
  {
    segment: APP_ROUTES.ROOT + "#find-your-cause",
    title: "Descubra sua causa",
    icon: "charity",
  },
  {
    kind: "header",
    title: "Doação de sangue",
  },
  {
    segment: APP_ROUTES.BLOOD_DONATIONS,
    title: "Quem pode doar",
    icon: "blood",
  },
  {
    kind: "divider",
  },
  {
    segment: `${APP_ROUTES.BLOOD_DONATIONS}#blood-banks`,
    title: "Bancos de Sangue",
    icon: "blood",
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Doação de órgãos",
  },
  {
    segment: "#seja-um-doador",
    title: "Seja um doador",
    icon: "realheart",
  },
  {
    kind: "header",
    title: "Imposto de Renda",
  },
  {
    segment: "#como-doar",
    title: "Como doar",
    icon: "monetization",
  },
  {
    kind: "divider",
  },
  {
    segment: "#pra-quem-doar",
    title: "Pra quem doar",
    icon: "monetization",
  },
  {
    kind: "divider",
  },
  {
    segment: "#doar-na-declaracao",
    title: "Doar na declaração",
    icon: "monetization",
  },
  {
    kind: "header",
    title: "Área da ONG",
  },
  {
    segment: APP_ROUTES.KNOWLEDGE_TRAILS,
    title: "Trilha de capacitação",
    icon: "ong",
  },
  {
    kind: "divider",
  },
  {
    segment: `${APP_ROUTES.KNOWLEDGE_TRAILS}#active-notices`,
    title: "Editais ativos",
    icon: "ong",
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Eventos que fazem o bem",
  },
  {
    segment: APP_ROUTES.EVENTS,
    title: "Agenda",
    icon: "calendar",
  },
];
