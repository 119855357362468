import React, { useState } from "react";
import {
  Divider,
  Grid,
  Skeleton,
  Typography,
  Box,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import OngsClient from "../api/OngsClient";
import NGOCard from "~/components/NGOs/Card";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import { useParams } from 'react-router-dom';
import SearchField from '~/components/SearchField';
import NoItemsMessage from "~/components/NoItemsMessage";

export default function NGOsPage() {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data: ongs = [],
    success,
    pending,
  } = useApiCall({
    apiCall: () => id ? OngsClient.cause_ngos(id) : OngsClient.ongs(),
    init: true,
    deps: [id],
  });

  const currentCause = id && ongs[0]?.causes?.find(cause => cause.id === parseInt(id));

  const filteredOngs = ongs.filter(ong => {
    return ong.name.toLowerCase().includes(searchTerm) || 
           (ong.description || '').toLowerCase().includes(searchTerm);
  });

  const renderSkeletons = () => (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {Array.from(new Array(6)).map((_, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <Skeleton 
              variant="rectangular" 
              height={216} 
              sx={{ borderRadius: 1 }}
              animation="wave"
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );

  if (pending) return renderSkeletons();

  if (!success || !ongs.length) {
    return <NoItemsMessage itemName="ONG" gender="feminino" />;
  }

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 4 } }}>
      <Box sx={{ 
        maxWidth: '100%',
        px: { xs: 2, sm: 0 }
      }}>
        <Typography 
          variant={isMobile ? "h5" : "h4"} 
          component="h1" 
          sx={{ 
            mb: 2, 
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          ONGs e Projetos da causa {currentCause?.name}
        </Typography>
        
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 4,
            textAlign: 'center',
            maxWidth: '800px'
          }}
        >
          {currentCause?.description}
        </Typography>

        <SearchField 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          label="Pesquisar ONGs..."
        />
        
        <Divider sx={{ width: "100%", mb: 4 }} />
        
        <Grid container spacing={3}>
          {filteredOngs.map((ong) => (
            <Grid item xs={12} sm={6} key={ong.id}>
              <NGOCard ong={ong} />
            </Grid>
          ))}
          {filteredOngs.length === 0 && searchTerm && (
            <Box sx={{ 
              width: '100%', 
              textAlign: 'center', 
              py: 4 
            }}>
              <Typography color="text.secondary">
                Nenhuma ONG encontrada com o termo "{searchTerm}"
              </Typography>
            </Box>
          )}
        </Grid>
      </Box>
    </Container>
  );
}
