import React from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import { useApiCall } from '~/single-page/hooks/useApiCall';
import GalleryNGOClient from '~/single-page/api/GalleryNGOClient';
import NoItemsMessage from '~/components/NoItemsMessage';

export default function GalleryCards({ ong, action, type = 'gallery' }) {
  const getImages = () => {
    if (action) {
      return type === 'gallery' ? action.gallery_images_url : action.accountability_images_url;
    } else if (ong) {
      return GalleryNGOClient.galleryNGO(ong.id);
    }
    return Promise.reject('Nem ONG nem Action fornecidos');
  };

  const {
    data: images = [],
    success,
    pending,
  } = useApiCall({
    apiCall: getImages,
    init: true,
  });

  if (pending) {
    return (
      <Box sx={{ 
        '& .swiper-slide': {
          display: 'flex',
          justifyContent: 'center'
        }
      }}>
        <Swiper
          modules={[Autoplay]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          spaceBetween={16}
          slidesPerView={1}
          breakpoints={{
            600: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 32,
            }
          }}
        >
          {Array.from({ length: 6 }).map((_, index) => (
            <SwiperSlide key={index}>
              <Box
                component="div"
                sx={{
                  width: { xs: '100%', sm: 250 },
                  height: 160,
                  bgcolor: 'grey.200',
                  borderRadius: 1
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    );
  }

  if (!success || !images || images.length === 0) {
    return <NoItemsMessage itemName="imagem" gender="feminino" />;
  }

  return (
    <Box sx={{ 
      '& .swiper-slide': {
        display: 'flex',
        justifyContent: 'center'
      }
    }}>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={images?.length > 1}
        spaceBetween={24}
        slidesPerView={1}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 24,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 40,
          }
        }}
      >
        {(images || []).map((imageUrl, index) => (
          <SwiperSlide key={index}>
            <Box
              component="img"
              src={imageUrl}
              alt={`Imagem ${index + 1}`}
              sx={{
                width: { xs: '100%', sm: 250 },
                height: 160,
                objectFit: 'cover',
                borderRadius: 1,
                cursor: 'pointer',
                margin: '12px 0'
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
