import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import CourseSlideCard from "./CourseSlideCard";
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const EMPTY_MESSAGES = {
  course: 'Nenhum curso disponível no momento',
  ebook: 'Nenhum e-book disponível no momento',
  site: 'Nenhum site disponível no momento',
  default: 'Nenhum item disponível'
};

const EmptyState = ({ type }) => (
  <Box 
    sx={{ 
      height: '100px',
      width: '100%',
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      backgroundColor: '#f5f5f5',
      borderRadius: 1,
      gap: 1
    }}
  >
    <ErrorOutlineIcon sx={{ color: '#666' }} />
    <Typography variant="body2" color="text.secondary">
      {EMPTY_MESSAGES[type] || EMPTY_MESSAGES.default}
    </Typography>
  </Box>
);

const NavigationButton = ({ direction }) => (
  <Box className={`swiper-button-${direction}`}>
    {direction === 'prev' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
  </Box>
);

export default function KnowledgeTrailsSlider({ courses = [], type = 'course' }) {  
  if (!courses.length) return <EmptyState type={type} />;

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        '& .actions-swiper': {
          width: '100%',
          height: '100%',
          padding: '0 40px'
        },
        '& .swiper-wrapper': {
          display: 'flex',
          alignItems: 'center',
          height: '100%'
        },
        '& .swiper-slide': {
          height: '120px',
          width: { xs: '100% !important', md: '250px !important' },
          margin: 0,
          flex: 'none',
          display: 'flex',
          alignItems: 'center',
          '& .course-card': {
            height: '100%',
            width: '100%'
          }
        },
        '& .swiper-button-next, & .swiper-button-prev': {
          top: '50%',
          transform: 'translateY(-50%)',
          width: '32px',
          height: '32px',
          margin: 0,
          zIndex: 1,
          '&::after': {
            display: 'none'
          },
          '& svg': {
            fontSize: 20,
            color: '#294BB6'
          }
        },
        '& .swiper-button-prev': {
          left: 0
        },
        '& .swiper-button-next': {
          right: 0
        }
      }}
    >
      <Swiper
        modules={[Navigation, Autoplay]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="actions-swiper"
        spaceBetween={8}
        slidesPerView={'auto'}
        breakpoints={{
          900: {
            slidesPerView: 'auto',
            spaceBetween: 8
          }
        }}
        slidesPerGroup={1}
        loop={false}
      >
        {courses.map((course, index) => (
          <SwiperSlide key={course.id || index}>
            <CourseSlideCard course={course} />
          </SwiperSlide>
        ))}
      </Swiper>
      <NavigationButton direction="prev" />
      <NavigationButton direction="next" />
    </Box>
  );
}
