import React from "react";
import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PlaceIcon from "@mui/icons-material/Place";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link as RouterLink } from "react-router-dom";
import { APP_ROUTES } from "~/single-page/constants/routes";

export default function ActionSlideCard({ action, hideOngName = false }) {
  return (
    <Card
      className="action-card"
      variant="outlined"
      sx={{
        display: "flex",
        backgroundColor: "white",
        width: "100%",
        height: "160px",
        gap: "4px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "16px !important",
          '&:last-child': {
            paddingBottom: "16px"
          }
        }}
      >
        <Typography 
          variant="h6" 
          fontWeight="bold" 
          fontSize="12px"
          sx={{ mb: 1 }}
        >
          {action.name.slice(0, 35)}
        </Typography>

        <Typography 
          variant="caption" 
          fontSize="10px"
          sx={{
            mb: 1,
            lineHeight: '16px',
            flex: '1 0 auto',
            maxHeight: '60px',
            overflow: 'hidden',
            textAlign: 'justify',
          }}
        >
          {action.short_description?.slice(0, 128)}
          {action.short_description?.length > 128 ? '...' : ''}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            mt: 'auto',
            flex: '0 0 auto',
            "& .MuiTypography-root": {
              display: "flex",
              alignItems: "center",
              minHeight: '20px',
              "& svg:first-of-type": { 
                mr: 1,
                fontSize: '16px'
              },
            },
          }}
        >
          {!hideOngName && (
            <Typography variant="caption" fontSize="12px">
              <ApartmentIcon color="secondary" fontSize="small" />
              {action.ong.name}
            </Typography>
          )}
          
          {action.whatsapp_contact && (
            <Typography variant="caption" fontSize="12px">
              <InfoOutlinedIcon color="secondary" fontSize="small" />
              <Link 
                component={RouterLink}
                to={`${APP_ROUTES.ACTION_MANAGEMENT}/${action.id}`}
                sx={{ 
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' }
                }}
              >
                Ver detalhes
              </Link>
            </Typography>
          )}
          
          {action.whatsapp_contact && (
            <Typography 
              variant="caption" 
              fontSize="12px"
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                '& svg': {
                  mt: '2px'
                }
              }}
            >
              <PlaceIcon color="secondary" fontSize="small" />
              <Box 
                component="span" 
                sx={{ 
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  wordBreak: 'break-word'
                }}
              >
                {action.ong.address}
              </Box>
            </Typography>
          )}

        </Box>
      </CardContent>
    </Card>
  );
}
