import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, TextField, IconButton, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function VolunteerModal({ open, onClose, ngo }) {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const volunteerSkills = ngo?.skills?.map(skill => ({
    id: skill.id,
    label: skill.name
  })) || [];

  const handleSkillChange = (skill) => {
    setSelectedSkills(prev => {
      if (prev.includes(skill)) {
        return prev.filter(s => s !== skill);
      }
      return [...prev, skill];
    });
  };

  const handleError = (message) => {
    setErrorMessage(message);
  };

  const generateWhatsAppMessage = () => {
    if (!ngo?.contact_phone) {
      handleError('Telefone de contato não disponível.');
      return;
    }

    const skills = selectedSkills
      .map(skill => volunteerSkills.find(s => s.id === skill)?.label)
      .filter(Boolean)
      .join(', ');
      
    const message = `Olá! Gostaria de me voluntariar na ${ngo.name} compartilhando as seguintes habilidades: ${skills}. Tenho muito interesse em contribuir com meu tempo e experiência para fazer a diferença na comunidade. Podemos conversar sobre as próximas etapas?`;
    
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/55${ngo.contact_phone}?text=${encodedMessage}`;
    
    window.open(whatsappLink, '_blank');
    onClose();
  };

  if (!ngo) {
    return null;
  }

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" fontWeight={700}>
            Seja Voluntário - {ngo.name}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: 'grey.500',
              '&:hover': {
                color: 'grey.700',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {errorMessage && (
            <Box sx={{ mb: 2 }}>
              <Alert 
                severity="error"
                sx={{ 
                  width: '100%',
                  '& .MuiAlert-message': {
                    width: '100%'
                  }
                }}
              >
                {errorMessage}
              </Alert>
            </Box>
          )}
          <Box>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Que incrível que você quer ser voluntário! Selecione as habilidades que você gostaria de compartilhar:
            </Typography>
            
            {volunteerSkills.length > 0 ? (
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 2,
                mb: 3
              }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {volunteerSkills.slice(0, 6).map((skill) => (
                    <Box key={skill.id} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={selectedSkills.includes(skill.id)}
                        onChange={() => handleSkillChange(skill.id)}
                      />
                      <Typography>{skill.label}</Typography>
                    </Box>
                  ))}
                </Box>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {volunteerSkills.slice(6, 12).map((skill) => (
                    <Box key={skill.id} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={selectedSkills.includes(skill.id)}
                        onChange={() => handleSkillChange(skill.id)}
                      />
                      <Typography>{skill.label}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Nenhuma habilidade cadastrada para esta ONG.
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, pb: 2 }}>
          <Button 
            onClick={onClose}
            variant="outlined"
            sx={{ 
              color: '#294BB6',
              borderColor: '#294BB6'
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={generateWhatsAppMessage}
            variant="contained"
            disabled={selectedSkills.length === 0}
            sx={{ 
              backgroundColor: '#294BB6',
              '&:hover': {
                backgroundColor: '#294BB6',
                opacity: 0.9
              }
            }}
          >
            Enviar Mensagem
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={!!errorMessage} 
        autoHideDuration={10000} 
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setErrorMessage(null)} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}