import React, { useState, useMemo } from 'react';
import { RailsContext } from '~/single-page/contexts/RailsContext';

export default function RailsProvider({ children, flash: initialFlash, currentUser: initialUser }) {
  const [currentUser, setCurrentUser] = useState(initialUser);
  const [flash, setFlash] = useState(initialFlash);

  const value = useMemo(() => ({
    flash,
    setFlash,
    currentUser,
    setCurrentUser,
    isAuthenticated: !!currentUser,
  }), [flash, currentUser]);

  return (
    <RailsContext.Provider value={value}>
      {children}
    </RailsContext.Provider>
  );
}
