import React from 'react';
import { Stack, IconButton, Tooltip } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import { ensureHttps } from '~/single-page/utils/urlHelpers';

export default function SocialMediaLinks({ socialLinks = {} }) {
  const {
    instagram,
    facebook,
    x,
  } = socialLinks;

  const socialIcons = [
    {
      id: 'instagram',
      icon: InstagramIcon,
      url: ensureHttps(instagram),
      label: 'Siga-nos no Instagram',
    },
    {
      id: 'facebook',
      icon: FacebookIcon,
      url: ensureHttps(facebook),
      label: 'Curta nossa página no Facebook',
    },
    {
      id: 'x',
      icon: XIcon,
      url: ensureHttps(x),
      label: 'Siga-nos no X',
    }
  ];

  return (
    <Stack 
      direction="row" 
      spacing={1}
      alignItems="center"
    >
      {socialIcons.map((social) => (
        social.url && (
          <Tooltip 
            key={social.id}
            title={social.label}
            arrow
            placement="top"
          >
            <IconButton
              aria-label={social.label}
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#1474FF',
                padding: '8px',
                '&:hover': {
                  backgroundColor: 'transparent',
                  transform: 'scale(1.1)',
                }
              }}
            >
              <social.icon sx={{ 
                width: 28,
                height: 28,
                '& svg': {
                  width: '100%',
                  height: '100%',
                  shapeRendering: 'crispEdges',
                  textRendering: 'optimizeLegibility',
                  filter: 'drop-shadow(0px 0px 0.5px rgba(0, 0, 0, 0.1))',
                },
                '@media (min-resolution: 2dppx)': {
                  transform: 'scale(0.95)',
                  shapeRendering: 'geometricPrecision'
                }
              }} />
            </IconButton>
          </Tooltip>
        )
      ))}
    </Stack>
  );
}
