import { createBrowserRouter } from "react-router-dom";
import AppLayout from "~/single-page/layouts/AppLayout";
import AuthLayout from "~/single-page/layouts/AuthLayout";
import Events from "~/single-page/pages/Events";
import SignIn from "~/single-page/pages/SignIn";
import SignUp from "~/single-page/pages/SignUp";
import ResetPassword from "~/single-page/pages/ResetPassword";
import RequestPasswordReset from "~/single-page/pages/RequestPasswordReset";
import FaleConosco from "~/components/FaleConosco";
import Opportunities from "~/single-page/pages/Oportunities";
import BloodDonations from "~/single-page/pages/BloodDonations";
import NGOsPage from "~/single-page/pages/NGOs";
import ActionManagementsPage from "~/single-page/pages/ActionManagements";
import { APP_ROUTES } from "~/single-page/constants/routes";
import NGODetailPage from '~/single-page/pages/NGODetail';
import ActionManagementDetailPage from '~/single-page/pages/ActionManagementDetail';
import KnowledgeTrailsPage from '~/single-page/pages/KnowledgeTrails';
import EventDetailPage from '~/single-page/pages/EventDetail';

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          path: `${APP_ROUTES.EVENTS}`,
          element: <Events />,
        },
        {
          path: `${APP_ROUTES.EVENT}/:id`,
          element: <EventDetailPage />,
        },
        {
          path: `${APP_ROUTES.CONTACT_US}`,
          element: <FaleConosco />,
        },
        {
          index: true,
          element: <Opportunities />,
        },
        {
          path: `${APP_ROUTES.CAUSES}/:id/ngos`,
          element: <NGOsPage />,
        },
        {
          path: `${APP_ROUTES.BLOOD_DONATIONS}`,
          element: <BloodDonations />,
        },
        {
          path: `${APP_ROUTES.ACTION_MANAGEMENTS}`,
          element: <ActionManagementsPage />,
        },
        {
          path: `${APP_ROUTES.ACTION_MANAGEMENT}/:id`,
          element: <ActionManagementDetailPage />,
        },
        {
          path: `${APP_ROUTES.NGO}/:id`,
          element: <NGODetailPage />,
        },
        {
          path: `${APP_ROUTES.KNOWLEDGE_TRAILS}`,
          element: <KnowledgeTrailsPage />,
        },
      ],
    },
    {
      element: <AuthLayout />,
      children: [
        {
          path: `${APP_ROUTES.SIGN_IN}`,
          element: <SignIn />,
        },
        {
          path: `${APP_ROUTES.RESET_PASSWORD}`,
          element: <ResetPassword />,
        },
        {
          path: `${APP_ROUTES.REQUEST_PASSWORD_RESET}`,
          element: <RequestPasswordReset />,
        },
        {
          path: `${APP_ROUTES.SIGN_UP}`,
          element: <SignUp />,
        },
      ],
    },
  ],
  {
    future: {
      v7_skipActionErrorRevalidation: true,
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_partialHydration: true,
      v7_normalizeFormMethod: true,
      v7_fetcherPersist: true
    }
  }
);
 