import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, TextField, Typography, Grid2, Link } from '@mui/material';
import { toast } from 'react-toastify';
import LoadingButton from '~/components/LoadingButton';
import rdbLogo from '~/images/illustrations/RECIFE\ DO\ BEM.svg';
import { useAuth } from '~/single-page/hooks/useAuth';
import { APP_ROUTES } from '~/single-page/constants/routes';

// Styled components
const Paper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  paddingBottom: theme.spacing(8),
}));

const SubmitButton = styled(LoadingButton)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  padding: theme.spacing(1.5),
  width: '100%',
  border: "2px solid #294BB6",
  "&:hover": {
    border: "2px solid #4F97FF",
    backgroundColor: "#4F97FF",
  },
  "&:active": {
    border: "2px solid #0F57BF",
    backgroundColor: "#0F57BF",
  },
  textTransform: "none",
}));

export default function SignIn() {
  const { signIn, loading, errors } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.activeElement.blur();
    toast.dismiss();
    await signIn(email, password);
  };

  return (
    <Paper>
      <div>
        <img src={rdbLogo} alt="Recife do Bem" />
      </div>
      <Typography variant="h6" sx={{ marginBottom: 2, fontSize: '28px', fontWeight: '700', lineHeight: '33.6px' }}>
        Entrar
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <TextField
          margin="dense"
          required
          label="Email"
          value={email}
          error={!!errors.email}
          helperText={errors.email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
          autoFocus
          sx={{ marginBottom: 1.5 }}
        />
        <TextField
          margin="dense"
          required
          label="Senha"
          value={password}
          error={!!errors.password}
          helperText={errors.password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          autoComplete="current-password"
          sx={{ marginTop: 1.5 }}
        />
        <Box sx={{ marginTop: 2 }} align="center">
          <Link href={APP_ROUTES.REQUEST_PASSWORD_RESET} underline="none" variant="body2">
            Esqueceu a senha?
          </Link>
        </Box>
        <SubmitButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          size="large"
          color="primary"
        >
          Entrar
        </SubmitButton>
      </form>
      <Grid2 container spacing={2} sx={{ marginTop: 3 }}>
        <Grid2 xs={12}>
          <Typography variant="body1" align="center">
            Não possui cadastro?
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Button fullWidth variant="outlined" component="a" size="large" color="primary" href={APP_ROUTES.SIGN_UP}>
            Criar conta
          </Button>
        </Grid2>
      </Grid2>
    </Paper>
  );
}
