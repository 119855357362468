import { createTheme } from "@mui/material/styles";

/**
 * Mui Default Theme configuration
*/

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mobile: 0,
      desktop: 701,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      margin: "normal",
      fullWidth: true,
    },
  },
  palette: {
    primary: {
      main: "#294BB6",
      light: "#4F97FF",
      dark: "#0F57BF",
    },
    background: {
      default: "#CBEFFF", // Background color for SignIn
    },
  },
  typography: {
    fontFamily: ["DM Sans", "sans-serif"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#CBEFFF", // Custom background color for the SignIn page
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        '[id]': {
          scrollMarginTop: '80px', // Adiciona margem para elementos com ID
        },
      },
    },
  },
});
