const ROUTES = {
  GET_USERS: '/admin/users.json',
  SIGN_OUT: '/users/sign_out.json',
  GET_ACTION_MANAGEMENTS: "/api/action_managements",
  GET_ONG_ACTION_MANAGEMENTS: (id) => `/api/ongs/${id}/action_managements`,
  GET_ACTION_MANAGEMENT: (id) => `/api/action_managements/${id}`,
  GET_CAUSES: "/api/causes",
  GET_CAUSE: (id) => `/api/causes/${id}`,
  GET_CAUSE_NGOS: (id) => `/api/causes/${id}/ngos`,
  GET_ONGS: "/api/ongs",
  GET_ONG: (id) => `/api/ongs/${id}`,
  GET_GALLERY_NGO: (id) => `/api/ongs/${id}/gallery_ngo`,
  GET_GALLERY_ACTION: (id) => `/api/action_managements/${id}/gallery_action`,
  GET_LOCATIONS: "/api/locations",
  GET_BLOOD_BANKS: "/api/blood_banks",
  GET_ODSs: "/api/odss",
  GET_KNOWLEDGE_TRAILS: "/api/knowledge_trails",
  GET_ACTIVE_NOTICES: "/api/active_notices",
  GET_EVENTS: "/api/events",
  GET_EVENT: (id) => `/api/events/${id}`
};

export default ROUTES;
