import React from "react";
import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ensureHttps } from '~/single-page/utils/urlHelpers';

export default function ActiveNoticeCard({ activeNotice }) {
  return (
    <Card
      className="action-card"
      variant="outlined"
      sx={{
        display: "flex",
        backgroundColor: "white",
        width: "100%",
        height: "160px",
        gap: "4px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "16px !important",
          '&:last-child': {
            paddingBottom: "16px"
          }
        }}
      >
        <Typography 
          variant="h6" 
          fontWeight="bold" 
          fontSize="12px"
          sx={{ mb: 1 }}
        >
          {activeNotice.title.slice(0, 35)}
        </Typography>

        <Typography 
          variant="caption" 
          fontSize="10px"
          sx={{
            mb: 1,
            lineHeight: '16px',
            flex: '1 0 auto',
            maxHeight: '65px',
            overflow: 'hidden',
            textAlign: 'justify',
          }}
        >
          {activeNotice.description?.slice(0, 185)}
          {activeNotice.description?.length > 185 ? '...' : ''}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            flex: '0 0 auto',
            "& .MuiTypography-root": {
              display: "flex",
              alignItems: "center",
              minHeight: '20px',
              "& svg:first-of-type": { 
                mr: 1,
                fontSize: '16px'
              },
            },
          }}
        >
          {activeNotice.registration_link && (
            <Typography variant="caption" fontSize="12px">
              <InfoOutlinedIcon color="secondary" fontSize="small" />
              <Link 
                href={ensureHttps(activeNotice.registration_link)}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' }
                }}
              >
                Ver detalhes
              </Link>
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
