import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessibleIcon from "@mui/icons-material/Accessible";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PhoneIcon from "@mui/icons-material/Phone";

export default function BloodBankCard({ bloodBank }) {
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          px: 4,
          py: 2,
          width: { xs: "346px", md: "825px" },
          margin: "0 auto",
        }}
      >
        <Box display="flex" alignItems="center" marginBottom={1}>
          <Typography display="flex" alignItems="center" variant="h5" gutterBottom>
            {bloodBank.name}&nbsp;&nbsp;&nbsp;
            <LocalParkingIcon color="secondary" sx={{ mr: 1 }}/>
            <AccessibleIcon color="secondary" sx={{ ml: 1 }}/>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginBottom={1}>
          <LocationOnIcon color="secondary" />
          <Typography marginLeft={1}>{bloodBank.address}</Typography>
        </Box>
        <Box display="flex" alignItems="center" marginBottom={1}>
          <PhoneIcon color="secondary" />
          <Typography marginLeft={1}>{bloodBank.phone}</Typography>
        </Box>
        <Box display="flex" alignItems="center" marginBottom={2}>
          <AccessTimeIcon color="secondary" />
          <Typography marginLeft={1}>{bloodBank.working_hours}</Typography>
        </Box>
        <Box display="flex" alignItems="center" marginBottom={2}>
          <EditCalendarIcon color="secondary" />
          <Link href={bloodBank.scheduling_link} marginLeft={1}>
            Link de agendamento
          </Link>
        </Box>
      </Paper>
    </>
  );
}
