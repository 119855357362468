import React from "react";

export default function BloodIcon() {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.2061 5.87091L16.6144 3.1198C16.4612 2.85498 16.2866 2.82853 16.1182 3.11969C16.1181 3.11972 16.1181 3.1198 16.1181 3.1198L14.5265 5.87091C13.7043 7.29201 14.7347 9.06684 16.3662 9.0694C17.9971 9.06684 19.0283 7.29223 18.2061 5.87091Z" fill="#EA473B" />
        <path d="M15.6246 13.6421L11.51 6.5301C11.5099 6.52991 11.5096 6.52973 11.5096 6.52954C10.9621 5.58303 10.4881 6.11314 10.3062 6.39209L10.2269 6.52928C10.2266 6.52935 10.2266 6.52954 10.2266 6.52954L6.11184 13.6421C3.98618 17.3161 6.65019 21.9048 10.8682 21.9112C11.8019 21.9098 12.748 21.6725 13.6143 21.1713C16.2487 19.6471 17.1485 16.2763 15.6246 13.6421Z" fill="#EA473B" />
        <path d="M15.291 5.87087L16.7484 3.35169C16.614 3.11935 16.6142 3.1198 16.6142 3.1198C16.461 2.85498 16.2865 2.82853 16.118 3.11969C16.118 3.11972 16.118 3.1198 16.118 3.1198L14.5263 5.87091C13.6191 7.43893 14.969 9.36127 16.7484 9.03404C15.3024 8.76782 14.5538 7.14495 15.291 5.87087Z" fill="#D63322" />
        <path d="M6.87639 13.642L10.9912 6.5295C10.9912 6.5295 10.9912 6.52931 10.9915 6.52924L11.0708 6.39205C11.1138 6.3261 11.1733 6.24621 11.2461 6.17499C10.8139 5.75146 10.4582 6.15904 10.3062 6.39205L10.2269 6.52924C10.2266 6.52931 10.2266 6.5295 10.2266 6.5295L6.11179 13.642C4.58767 16.2763 5.48783 19.647 8.12208 21.1712C8.9884 21.6724 9.93443 21.9098 10.8681 21.9112C10.9954 21.911 11.1229 21.906 11.2504 21.897C7.207 21.6105 4.83593 17.1687 6.87639 13.642Z" fill="#D63322" />
      </svg>
    </>
  )
}
