import React from "react";
import { 
  Button, 
  Box, 
  Card, 
  CardContent, 
  CardActions, 
  Typography, 
  Link,
  useTheme,
  useMediaQuery 
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import ApartmentIcon from "@mui/icons-material/Apartment";
import PlaceIcon from "@mui/icons-material/Place";
import ShareIcon from '@mui/icons-material/Share';
import { Link as RouterLink } from 'react-router-dom';
import { APP_ROUTES } from "~/single-page/constants/routes";
import { useDonationModal } from '~/single-page/hooks/useDonationModal';
import { useVolunteerModal } from '~/single-page/hooks/useVolunteerModal';

export default function ActionCard({ action }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { 
    handleOpenDonationModal, 
    DonationModalComponent 
  } = useDonationModal();
  const { 
    handleOpenVolunteerModal, 
    VolunteerModalComponent 
  } = useVolunteerModal();

  return (
    <Card
      className="action-management-card"
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        width: '100%',
        height: "100%",
        p: { xs: 2, md: 3 },
        gap: 1,
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: theme.shadows[3],
          transform: 'translateY(-2px)'
        }
      }}
    >
      <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
        <Box className="header" sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 2
        }}>
          <Typography 
            variant={isMobile ? "subtitle1" : "h6"} 
            fontWeight="bold"
            sx={{ 
              flex: 1,
              mr: 1,
              lineHeight: 1.2
            }}
          >
            {action.name}
          </Typography>
          <IconButton 
            aria-label="compartilhe"
            size="small"
            sx={{ 
              '&:hover': { 
                backgroundColor: 'rgba(0, 0, 0, 0.04)' 
              }
            }}
          >
            <ShareIcon color="secondary" fontSize="small" />
          </IconButton>
        </Box>

        <Typography 
          variant="body2" 
          sx={{ 
            mb: 2,
            color: 'text.secondary',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden'
          }}
        >
          {action.short_description}
        </Typography>

        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mb: 2
        }}>
          <Typography 
            variant="body2" 
            sx={{ 
              display: "flex",
              alignItems: "center",
              gap: 1
            }}
          >
            <ApartmentIcon color="secondary" fontSize="small" />
            {action.ong.name}
          </Typography>

          {action.ong.address && (
            <Typography 
              variant="body2"
              sx={{ 
                display: "flex",
                alignItems: "center",
                gap: 1
              }}
            >
              <PlaceIcon color="secondary" fontSize="small" />
              {action.ong.address}
            </Typography>
          )}
        </Box>
      </CardContent>

      <CardActions 
        sx={{ 
          p: 0,
          mt: 'auto',
          gap: { xs: 1, sm: 2 },
          flexWrap: 'wrap',
          '& .MuiButton-root': { 
            textTransform: 'none',
            flex: isMobile ? '1 1 auto' : 'initial'
          }
        }}
      >
        <Button 
          variant="contained" 
          color="secondary"
          size={isMobile ? "medium" : "large"}
          onClick={handleOpenVolunteerModal}
        >
          Participar
        </Button>
        
        {action.ong.is_formalized && (
          <Button 
            onClick={handleOpenDonationModal}
            color="secondary" 
            variant="outlined"
            size={isMobile ? "medium" : "large"}
          >
            Doar
          </Button>
        )}
        
        <Button 
          component={RouterLink}
          to={`${APP_ROUTES.NGO}/${action.ong.id}`}
          color="secondary"
          size={isMobile ? "medium" : "large"}
        >
          {action.ong.is_formalized ? "Sobre a ONG" : "Sobre o projeto"}
        </Button>
      </CardActions>
      <DonationModalComponent ngo={action.ong} />
      <VolunteerModalComponent ngo={action.ong} />
    </Card>
  );
}
