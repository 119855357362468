import React from "react";

export default function OngIcon() {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.39 16.2152C19.6134 16.2152 19.8312 16.2397 20.0405 16.2868V11.0696H16.7007V16.2868C16.91 16.2397 17.1278 16.2152 17.3512 16.2152H19.39Z" fill="#009FE3" />
        <path d="M13.4351 16.2868V11.0696H10.0952V16.2868C10.3045 16.2397 10.5223 16.2152 10.7457 16.2152H12.7845C13.0079 16.2152 13.2258 16.2397 13.4351 16.2868Z" fill="#009FE3" />
        <path d="M6.17906 16.2152C6.40246 16.2152 6.62029 16.2397 6.82959 16.2868V11.0696H3.48975V16.2868C3.69904 16.2397 3.91687 16.2152 4.14027 16.2152H6.17906Z" fill="#009FE3" />
        <path d="M12.3693 4.97641V4.97679L12.1585 5.1872C11.9411 5.40466 11.5885 5.40466 11.3711 5.1872L11.1603 4.97679C10.9898 4.80553 10.7125 4.80631 10.5428 4.97679C10.3725 5.14675 10.3725 5.42395 10.5428 5.59429L11.7648 6.81593L12.9868 5.59429C13.0692 5.51153 13.1148 5.40206 13.1148 5.28554C13.1148 5.16864 13.0692 5.05917 12.9868 4.97679C12.817 4.80623 12.54 4.80575 12.3693 4.97641Z" fill="#009FE3" />
        <path d="M20.7085 7.3552H17.038C16.9204 7.3552 16.8313 7.25834 16.8313 7.13922V7.13105C16.8313 4.33523 14.5591 2.06043 11.7655 2.06006H11.7651C8.97154 2.06006 6.69897 4.33523 6.69897 7.13105C6.69897 7.27133 6.60063 7.3552 6.49227 7.3552H2.82178C2.51451 7.3552 2.26514 7.60494 2.26514 7.91258V9.39881C2.26514 9.70682 2.51451 9.95619 2.82178 9.95619H20.7085C21.0158 9.95619 21.2651 9.70682 21.2651 9.39881V7.91258C21.2651 7.60494 21.0158 7.3552 20.7085 7.3552ZM13.7742 6.38145L12.1589 7.99682C11.9413 8.21439 11.5892 8.21465 11.3714 7.99682L9.75604 6.38145C9.15152 5.7773 9.15152 4.79391 9.75604 4.18939C10.3144 3.63101 11.1719 3.5988 11.7651 4.03242C12.3586 3.59861 13.2161 3.63123 13.7742 4.18939C14.3805 4.79569 14.3801 5.77556 13.7742 6.38145Z" fill="#009FE3" />
        <path d="M2.26514 20.5029C2.26514 20.8102 2.51451 21.06 2.82178 21.06H20.7085C21.0158 21.06 21.2651 20.8102 21.2651 20.5029V19.9456H2.26514V20.5029Z" fill="#009FE3" />
        <path d="M9.09326 18.8324H14.437C14.2637 17.9752 13.5056 17.3284 12.599 17.3284H10.9313C10.0247 17.3284 9.26656 17.9752 9.09326 18.8324Z" fill="#009FE3" />
        <path d="M2.30225 18.8324H8.01709C7.84379 17.9752 7.08564 17.3284 6.17906 17.3284H4.14027C3.23369 17.3284 2.47555 17.9752 2.30225 18.8324Z" fill="#009FE3" />
        <path d="M15.5132 18.8324H21.228C21.0547 17.9752 20.2966 17.3284 19.39 17.3284H17.3512C16.4446 17.3284 15.6865 17.9752 15.5132 18.8324Z" fill="#009FE3" />
      </svg>
    </>
  )
}
