import React, { useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FileField from '~/components/FileField.jsx';
import LoadingButton from '~/components/LoadingButton.jsx';
import AuthorizationClient from '../api/AuthorizationClient';

const rdmcolor = '#D12E5E';

export default function SignUp() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    avatar: '',
    role_ids: ["3"]
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const sign_up = (e) => {
    e.preventDefault();
    document.activeElement.blur();
    setLoading(true);
    toast.dismiss();

    AuthorizationClient.signUp(user)
      .then(() => {
        toast.success('Olhe sua caixa de e-mail para concluir o processo de cadastro');
        navigate('/site/sign_in');
      })
      .catch((response) => {
        if (response.status === 422) {
          setErrors(response.data.errors);
        } else {
          setErrors({});
        }
        toast.error(response.data?.error || 'Erro ao processar solicitação.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 3,
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: rdmcolor }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Criar minha conta
      </Typography>
      <Box
        component="form"
        onSubmit={sign_up}
        noValidate
        sx={{ mt: 1, width: '100%' }}
      >
        <FileField
          variant="outlined"
          name="avatar"
          fullWidth
          id="avatar"
          label="Avatar"
          error={!!errors.avatar}
          helperText={errors.avatar ? errors.avatar.join(', ') : ''}
          value={user.avatar ? user.avatar.path : ''}
          onChange={(e) => setUser({ ...user, avatar: e.target.files[0] })}
        />
        {[
          {
            name: 'name',
            label: 'Nome',
            type: 'text',
            autoFocus: true,
            required: true,
          },
          { name: 'email', label: 'Email', type: 'email', required: true },
          { name: 'password', label: 'Senha', type: 'password', required: true },
          {
            name: 'password_confirmation',
            label: 'Confirmação da senha',
            type: 'password',
            required: true,
          },
        ].map((field) => (
          <TextField
            required={field.required}
            key={field.name}
            id={field.name}
            name={field.name}
            label={field.label}
            fullWidth
            autoComplete={field.name}
            autoFocus={field.autoFocus}
            value={user[field.name]}
            error={!!errors[field.name]}
            type={field.type}
            helperText={errors[field.name] ? errors[field.name].join(', ') : ''}
            onChange={(e) => setUser({ ...user, [field.name]: e.target.value })}
            sx={{ mt: 2 }}
          />
        ))}
        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Criar minha conta
        </LoadingButton>
      </Box>
      <Grid container>
        <Grid item xs>
          <Typography
            component="a"
            href="/site/sign_in"
            variant="body2"
            sx={{ textDecoration: 'none' }}
          >
            Entrar
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            component="a"
            href="/site/request_password_reset"
            variant="body2"
            sx={{ textDecoration: 'none' }}
          >
            Esqueceu sua senha?
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
