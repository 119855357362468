import React from 'react'

export default function LightBulbIcon() {
  return (
    <>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2065_2651)">
          <path d="M9 21.074C9 21.624 9.45 22.074 10 22.074H14C14.55 22.074 15 21.624 15 21.074V20.074H9V21.074ZM12 2.07397C8.14 2.07397 5 5.21398 5 9.07398C5 11.454 6.19 13.544 8 14.814V17.074C8 17.624 8.45 18.074 9 18.074H15C15.55 18.074 16 17.624 16 17.074V14.814C17.81 13.544 19 11.454 19 9.07398C19 5.21398 15.86 2.07397 12 2.07397ZM14.85 13.174L14 13.774V16.074H10V13.774L9.15 13.174C7.8 12.234 7 10.704 7 9.07398C7 6.31398 9.24 4.07397 12 4.07397C14.76 4.07397 17 6.31398 17 9.07398C17 10.704 16.2 12.234 14.85 13.174Z" fill="#009FE3" />
        </g>
        <defs>
          <clipPath id="clip0_2065_2651">
            <rect width="24" height="24" fill="white" transform="translate(0 0.0739746)" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
