import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const scrollToElement = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        // Se o elemento não for encontrado imediatamente, tenta novamente após um delay
        setTimeout(() => {
          const retryElement = document.getElementById(id);
          if (retryElement) {
            retryElement.scrollIntoView({ behavior: 'smooth' });
          } else {
            scrollToTop();
          }
        }, 500); // Aguarda 500ms para tentar novamente
      }
    };

    if (location.hash) {
      const id = location.hash.slice(1);
      // Pequeno delay para garantir que a página foi carregada
      setTimeout(() => {
        scrollToElement(id);
      }, 100);
    } else {
      scrollToTop();
    }

  }, [location]); // Observa qualquer mudança na location
};