import { useState, useEffect, useCallback } from "react";

export const useApiCall = ({ apiCall, init, initialData = [] }) => {
    /* initialize data as an empty array by default */
    const [data, setData] = useState(initialData);
    const [errors, setErrors] = useState(null);
    const [pending, setPending] = useState(false);
    const [success, setSuccess] = useState(false);

    const makeApiCall = useCallback(async() => {
        setPending(true);
        try {
            const response = await apiCall();
            setData(response?.data || response);
            setSuccess(true);
        } catch (error) {
            setErrors(error);
            setSuccess(false);
        } finally {
            setPending(false);
        }
    }, []);

    useEffect(() => {
        if (init) {
            makeApiCall();
        }
    }, [init, makeApiCall]);

    return {
        data,
        makeApiCall,
        errors,
        success,
        pending,
    };
};
