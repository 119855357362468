import axiosClient from "./axiosClient.js";
import ROUTES from "./api_routes.js";

async function activeNotices() {
  return axiosClient.get(ROUTES.GET_ACTIVE_NOTICES);
}

const ActiveNoticesClient = {
  activeNotices,
};

export default ActiveNoticesClient;
