import React from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Skeleton from "@mui/material/Skeleton";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import { useParams } from 'react-router-dom';
import NoItemsMessage from "~/components/NoItemsMessage";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventsClient from "../api/EventsClient";
import { useScrollToSection } from '~/single-page/hooks/useScrollToSection';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import '~/theme/styles/actiontext.scss';

const LoadingSkeleton = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Skeleton variant="rectangular" height={118} />
    </Grid>
  </Grid>
);

const ActionButton = ({ variant, onClick, children, outlined, component, to, endIcon }) => (
  <Button
    variant={variant}
    onClick={onClick}
    component={component}
    to={to}
    fullWidth
    endIcon={endIcon}
    sx={{
      backgroundColor: outlined ? 'transparent' : '#294BB6',
      color: outlined ? '#294BB6' : 'white',
      borderColor: '#294BB6',
      '&:hover': {
        backgroundColor: outlined ? 'transparent' : '#294BB6',
        borderColor: '#294BB6',
        opacity: 0.8
      },
      py: 1.5,
      textTransform: 'none',
      fontWeight: outlined ? 600 : 700
    }}
  >
    {children}
  </Button>
);

export default function EventDetailPage() {
  const { id } = useParams();
  useScrollToSection();

  const {
    data: event = null,
    success,
    pending,
  } = useApiCall({
    apiCall: () => id ? EventsClient.event(id) : Promise.reject('ID não fornecido'),
    init: true,
    deps: [id],
  });

  const formatEventDate = (dateString, isCompact = false) => {
    const date = new Date(dateString);
    
    if (isCompact) {
      const day = format(date, 'dd');
      const month = format(date, 'MMM', { locale: ptBR });
      const time = format(date, 'HH:mm');
      const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
      
      return `${day} ${capitalizedMonth}${!event.all_day ? ` • ${time}` : ''}`;
    }
    
    const weekDay = format(date, 'EEEE', { locale: ptBR });
    const day = format(date, 'dd');
    const month = format(date, 'MMM', { locale: ptBR });
    const time = format(date, 'HH:mm');
    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
    
    return `${weekDay}, ${day} de ${capitalizedMonth}${!event.all_day ? ` às ${time}` : ''}`;
  };

  const formatDateRange = () => {
    if (event.all_day) {
      return (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <CalendarTodayIcon sx={{ color: 'text.secondary' }} />
          <Typography variant="body1" color="text.secondary">
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              {formatEventDate(event.start_date)} - {formatEventDate(event.end_date)}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
              {formatEventDate(event.start_date, true)} - {formatEventDate(event.end_date, true)}
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              {formatEventDate(event.start_date, true)} - {formatEventDate(event.end_date, true)}
            </Box>
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CalendarTodayIcon 
          sx={{ 
            color: 'text.secondary',
            mt: 1.5
          }} 
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography variant="body1" color="text.secondary">
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              Início: {formatEventDate(event.start_date)}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
              Início: {formatEventDate(event.start_date, true)}
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              Início: {formatEventDate(event.start_date, true)}
            </Box>
          </Typography>
          <Typography variant="body1" color="text.secondary">
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              Término: {formatEventDate(event.end_date)}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
              Término: {formatEventDate(event.end_date, true)}
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              Término: {formatEventDate(event.end_date, true)}
            </Box>
          </Typography>
        </Box>
      </Box>
    );
  };

  if (pending) return <LoadingSkeleton />;
  if (!success || !event) return <NoItemsMessage itemName="evento" gender="masculino" />;

  return (
    <Stack spacing={4} sx={{ maxWidth: '100%', px: 2, py: 3 }}>
      <Box sx={{ display: 'flex', gap: 3, flexDirection: { xs: 'column', md: 'row' } }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            gap: 2,
            mb: 3
          }}>
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
              {event.title}
            </Typography>

            <Box sx={{ minWidth: '200px' }}>
              <ActionButton
                variant="contained"
                onClick={() => window.open(event.website_link, '_blank')}
                sx={{
                  height: '48px',
                  fontSize: '1rem'
                }}
              >
                Ir ao site do evento
              </ActionButton>
            </Box>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {formatDateRange()}
          </Box>

          <Typography 
            variant="body1" 
            sx={{ mt: 3, mb: 4 }}
            component="div"
            className="trix-content"
            dangerouslySetInnerHTML={{ __html: event.description_text.self }}
          />
        </Box>
      </Box>
    </Stack>
  );
}
