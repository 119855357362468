import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Input,
  FilledInput,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  Avatar,
} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';

const variantComponent = {
  standard: Input,
  filled: FilledInput,
  outlined: OutlinedInput,
};

const FileField = React.forwardRef(function FileField(props, ref) {
  const {
    accept,
    showPreview = true,
    showUploadAdornment = true,
    autoComplete,
    autoFocus = false,
    className,
    color = 'primary',
    defaultValue,
    disabled = false,
    error = false,
    FormHelperTextProps,
    fullWidth = false,
    helperText,
    hiddenLabel,
    id,
    InputLabelProps = { shrink: true },
    inputProps,
    InputProps,
    inputRef,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required = false,
    value,
    variant = 'standard',
    ...other
  } = props;

  const [preview, setPreview] = useState(null);
  const [localValue, setLocalValue] = useState(null);
  const fileInput = useRef();

  const localHandleChange = (e) => {
    const file = e.target.files[0];
    if (accept && !new RegExp(accept.replace(',', '|')).test(file.type)) {
      alert("Invalid file type.");
      return;
    }
    setLocalValue(file);
    onChange?.(e);
  };

  const handleUploadClick = (e) => {
    if (localValue) {
      fileInput.current.value = '';
      setLocalValue(null);
      setPreview(null);
      e.preventDefault();
      onChange?.({
        target: {
          value: '',
          files: [],
        },
      });
    }
  };

  useEffect(() => {
    if (localValue) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(localValue);
    }
  }, [localValue]);

  const InputComponent = variantComponent[variant];
  const InputElement = (
    <InputComponent
      aria-describedby={helperText && id ? `${id}-helper-text` : undefined}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      name={name}
      type="file"
      ref={fileInput}
      value={value}
      id={id}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={localHandleChange}
      onFocus={onFocus}
      placeholder={placeholder}
      inputProps={inputProps}
      {...InputProps}
      startAdornment={
        showPreview && (
          <InputAdornment position="start">
            <label htmlFor={name}>
              <IconButton
                color={color}
                aria-label={`${label || 'File'} preview`}
                component="span"
              >
                <Avatar src={preview}>
                  {!preview && <PhotoCameraIcon />}
                </Avatar>
              </IconButton>
            </label>
          </InputAdornment>
        )
      }
      endAdornment={
        showUploadAdornment && (
          <InputAdornment position="end">
            <label htmlFor={name}>
              <IconButton
                color={color}
                aria-label={`${localValue ? 'Remove' : 'Upload'} file`}
                component="span"
                onClick={handleUploadClick}
              >
                {localValue ? <CancelIcon /> : <CloudUploadIcon />}
              </IconButton>
            </label>
          </InputAdornment>
        )
      }
    />
  );

  return (
    <FormControl
      className={clsx(className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      ref={ref}
      required={required}
      variant={variant}
      sx={{
        margin: '8px 0',
        ...(fullWidth && { width: '100%' }),
      }}
      {...other}
    >
      {label && (
        <InputLabel htmlFor={id} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      {InputElement}
      {helperText && (
        <FormHelperText id={`${id}-helper-text`} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});

FileField.propTypes = {
  accept: PropTypes.string,
  showPreview: PropTypes.bool,
  showUploadAdornment: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  hiddenLabel: PropTypes.bool,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.object,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default FileField;
