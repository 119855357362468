import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

const cardStyles = {
  display: "flex",
  backgroundColor: "white",
  width: '100%',
  height: '120px',
  position: "relative",
  overflow: "hidden"
};

const imageStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  opacity: 0.8
};

const contentStyles = {
  display: "flex",
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  position: 'relative',
  zIndex: 1,
  '&:last-child': {
    paddingBottom: '12px'
  }
};

const withImageTitleStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  lineHeight: 1.2,
  fontSize: '0.875rem',
  textAlign: 'center',
  maxWidth: '90%',
  fontWeight: 'bold',
  color: 'white',
  textShadow: '0 1px 2px rgba(0,0,0,0.6)',
  backgroundColor: 'rgba(0,0,0,0.4)',
  padding: '4px 8px',
  borderRadius: '4px'
};

const noImageContentStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  padding: '16px !important',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)', // Garante centralização perfeita
  background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'
};

const noImageTitleStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  lineHeight: 1.2,
  fontSize: '1rem',
  textAlign: 'center',
  maxWidth: '90%',
  fontWeight: 'bold',
  color: '#333',
  marginTop: '8px' // Espaço entre o ícone e o texto
};

const iconStyles = {
  fontSize: '2rem',
  color: '#666',
  opacity: 0.5,
  marginBottom: '4px'
};

export default function CourseSlideCard({ course }) {
  const hasImage = Boolean(course.cover_image_url);

  if (!hasImage) {
    return (
      <Card className="course-card" variant="outlined" sx={cardStyles}>
        <Box sx={noImageContentStyles}>
          <AutoStoriesIcon sx={iconStyles} />
          <Typography sx={noImageTitleStyles}>
            {course.name}
          </Typography>
        </Box>
      </Card>
    );
  }

  return (
    <Card className="course-card" variant="outlined" sx={cardStyles}>
      <Box
        component="img"
        src={course.cover_image_url}
        alt={course.name}
        sx={imageStyles}
      />
      
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.4))',
          zIndex: 0
        }}
      />

      <CardContent sx={contentStyles}>
        <Typography sx={withImageTitleStyles}>
          {course.name}
        </Typography>
      </CardContent>
    </Card>
  );
}
