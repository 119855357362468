import { useState } from 'react';
import VolunteerModal from '~/components/NGOs/VolunteerModal';

export const useVolunteerModal = () => {
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);

  const handleOpenVolunteerModal = () => setIsVolunteerModalOpen(true);
  const handleCloseVolunteerModal = () => setIsVolunteerModalOpen(false);

  const VolunteerModalComponent = ({ ngo }) => {
    if (!ngo) return null;
    return (
      <VolunteerModal 
        open={isVolunteerModalOpen} 
        onClose={handleCloseVolunteerModal} 
        ngo={ngo} 
      />
    );
  };

  return {
    isVolunteerModalOpen,
    handleOpenVolunteerModal,
    handleCloseVolunteerModal,
    VolunteerModalComponent
  };
};
