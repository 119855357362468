import * as React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import PrefeituraLogo from "~/images/illustrations/logo-prefeitura-do-recife.svg";
import { APP_ROUTES } from "~/single-page/constants/routes";

const FooterLink = ({ to, children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
  return (
    <Typography
      variant="body1"
      component={Link}
      to={to}
      sx={{
        color: 'white',
        textDecoration: 'none',
        display: 'block',
        mb: 1.5,
        transition: 'opacity 0.2s ease',
        textAlign: isMobile ? 'center' : 'left',
        '&:hover': {
          opacity: 0.8
        }
      }}
    >
      {children}
    </Typography>
  );
};

const MENU_ITEMS = [
  { title: 'Voluntariado e doação', path: APP_ROUTES.ROOT },
  { title: 'Doação de Sangue', path: APP_ROUTES.BLOOD_DONATIONS },
  { title: 'Imposto de Renda', path: APP_ROUTES.BLOOD_DONATIONS },
  { title: 'Área da ONG', path: APP_ROUTES.BLOOD_DONATIONS },
  { title: 'Agenda de eventos', path: APP_ROUTES.EVENTS }
];

export default function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        backgroundColor: "#009FE3",
        py: { xs: 4, md: 6 },
        mt: 'auto'
      }}
    >
      <Container maxWidth="lg">
        <Grid 
          container 
          spacing={{ xs: 4, md: 8 }}
          sx={{ 
            px: { xs: 2, sm: 3, md: 4 }
          }}
        >
          {/* Logo e Descrição */}
          <Grid 
            item 
            xs={12} 
            md={6}
            sx={{
              textAlign: isMobile ? 'center' : 'left',
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start'
            }}
          >
            <Box 
              sx={{ 
                mb: { xs: 2, md: 4 },
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-start',
                width: '100%'
              }}
            >
              <Link to={APP_ROUTES.ROOT}>
                <img 
                  src={PrefeituraLogo} 
                  alt="Logo da Prefeitura do Recife"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    marginBottom: '24px'
                  }}
                />
              </Link>
            </Box>
            
            <Typography 
              variant={isMobile ? "h6" : "h5"} 
              fontWeight="bold" 
              color="white"
              sx={{ 
                mb: 1,
                textAlign: isMobile ? 'center' : 'left'
              }}
            >
              Recife do bem
            </Typography>
            
            <Typography 
              variant="body1" 
              color="white"
              sx={{ 
                opacity: 0.9,
                textAlign: isMobile ? 'center' : 'left'
              }}
            >
              A união que transforma vidas
            </Typography>
          </Grid>

          {/* Menu Links */}
          <Grid 
            item 
            xs={12} 
            md={6}
            sx={{
              textAlign: isMobile ? 'center' : 'left'
            }}
          >
            <Typography 
              variant="h6" 
              color="white" 
              fontWeight="bold" 
              sx={{ 
                mb: { xs: 2, md: 4 },
                textAlign: isMobile ? 'center' : 'left'
              }}
            >
              Portal
            </Typography>

            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: isMobile ? 'center' : 'flex-start'
              }}
            >
              {MENU_ITEMS.map((item, index) => (
                <FooterLink 
                  key={`footer-link-${index}`} 
                  to={item.path}
                >
                  {item.title}
                </FooterLink>
              ))}
            </Box>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box 
          sx={{ 
            mt: { xs: 4, md: 6 },
            pt: 3,
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            textAlign: 'center'
          }}
        >
          <Typography 
            variant="body2" 
            color="white" 
            sx={{ opacity: 0.7 }}
          >
            © {new Date().getFullYear()} Prefeitura do Recife. Todos os direitos reservados.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
