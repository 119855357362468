import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Skeleton from "@mui/material/Skeleton";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import OngsClient from "~/single-page/api/OngsClient";
import { Box, Typography } from "@mui/material";
import NoItemsMessage from "~/components/NoItemsMessage";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Usando CDN - não precisa de arquivos locais
const icon = L.icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/marker-icon.png',
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/marker-icon-2x.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const NGOsMap = () => {
  const {
    data: ngosLocations = [],
    pending,
    success,
    error
  } = useApiCall({
    apiCall: OngsClient.locations,
    init: true,
    onSuccess: (data) => {
      console.log('Dados recebidos com sucesso:', data);
    },
    onError: (err) => {
      console.error('Erro na requisição:', err);
    }
  });

  const defaultPosition = [-23.5505, -46.6333]; // São Paulo

  const renderMap = () => {
    if (pending) {
      return (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={484}
          animation="wave"
          sx={{ borderRadius: 1 }}
        />
      );
    }

    if (!success || error || !ngosLocations?.length) {
      return <NoItemsMessage itemName="ONG" gender="feminino" />;
    }

    return (
      <Box 
        sx={{ 
          width: "100%",
          height: 484,
          borderRadius: 1,
          overflow: "hidden"
        }}
      >
        <MapContainer 
          center={ngosLocations[0]?.location || defaultPosition}
          zoom={13}
          style={{ width: '100%', height: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {ngosLocations.map((item, index) => (
            <Marker 
              key={item.key || index}
              position={[item.location.lat, item.location.lng]}
              icon={icon}
            >
              <Popup>
                <Typography variant="subtitle2">{item.key}</Typography>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </Box>
    );
  };

  return (
    <>
      <Typography 
        variant="h6" 
        component="h3" 
        sx={{ 
          mb: 4,
          fontWeight: 'bold'
        }}
      >
        Encontre no mapa ações e projetos
      </Typography>
      {renderMap()}
    </>
  );
};

export default NGOsMap;
