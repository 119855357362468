import React from "react";
import { Stack, Box, Typography, Button, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Skeleton from "@mui/material/Skeleton";
import { useApiCall } from "~/single-page/hooks/useApiCall";
import { useParams, Link as RouterLink } from 'react-router-dom';
import NoItemsMessage from "~/components/NoItemsMessage";
import ShareIcon from '@mui/icons-material/Share';
import GalleryCards from "~/components/NGOs/GalleryCards";
import { useDonationModal } from '~/single-page/hooks/useDonationModal';
import { useVolunteerModal } from '~/single-page/hooks/useVolunteerModal';
import ODSsCards from "~/components/ODSs/Cards";
import { APP_ROUTES } from "~/single-page/constants/routes";
import ActionManagementsClient from "../api/ActionManagementsClient";
import { useScrollToSection } from '~/single-page/hooks/useScrollToSection';

const LoadingSkeleton = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Skeleton variant="rectangular" height={118} />
    </Grid>
  </Grid>
);

const ActionButton = ({ variant, onClick, children, outlined, component, to, endIcon }) => (
  <Button
    variant={variant}
    onClick={onClick}
    component={component}
    to={to}
    fullWidth
    endIcon={endIcon}
    sx={{
      backgroundColor: outlined ? 'transparent' : '#294BB6',
      color: outlined ? '#294BB6' : 'white',
      borderColor: '#294BB6',
      '&:hover': {
        backgroundColor: outlined ? 'transparent' : '#294BB6',
        borderColor: '#294BB6',
        opacity: 0.8
      },
      py: 1.5,
      textTransform: 'none',
      fontWeight: outlined ? 600 : 700
    }}
  >
    {children}
  </Button>
);

export default function ActionManagementDetailPage() {
  const { id } = useParams();
  useScrollToSection();

  const {
    data: action = null,
    success,
    pending,
  } = useApiCall({
    apiCall: () => id ? ActionManagementsClient.actionManagement(id) : Promise.reject('ID não fornecido'),
    init: true,
    deps: [id],
  });

  const { handleOpenDonationModal, DonationModalComponent } = useDonationModal();
  const { handleOpenVolunteerModal, VolunteerModalComponent } = useVolunteerModal();

  if (pending) return <LoadingSkeleton />;
  if (!success || !action) return <NoItemsMessage itemName="ação" gender="feminino" />;

  return (
    <Stack spacing={4} sx={{ maxWidth: '100%', px: 2, py: 3 }}>
      <Box sx={{ display: 'flex', gap: 3, flexDirection: { xs: 'column', md: 'row' } }}>
        {/* Coluna da esquerda */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
            {action.name}
          </Typography>

          <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
            {action.ong.name}
          </Typography>

          <Typography variant="body1" sx={{ mt: 3 }}>
            {action.description}
          </Typography>
        </Box>

        {/* Box da direita */}
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            boxShadow: 3,
            position: { xs: 'static', md: 'sticky' },
            top: 24,
            width: { xs: '100%', md: '300px' },
            alignSelf: 'flex-start'
          }}
        >
          <ActionButton variant="contained" onClick={handleOpenDonationModal}>
            Quero doar
          </ActionButton>

          <ActionButton variant="contained" onClick={handleOpenVolunteerModal}>
            Quero ser um voluntário
          </ActionButton>

          <ActionButton 
            variant="outlined" 
            outlined 
            onClick={() => console.log('Compartilhar clicado')}
            endIcon={<ShareIcon sx={{ color: '#294BB6' }} />}
          >
            Compartilhar
          </ActionButton>

          <ActionButton
            variant="outlined"
            outlined
            component={RouterLink}
            to={`${APP_ROUTES.NGO}/${action.ong.id}`}
          >
            {action.ong.is_formalized ? "Conheça a ONG" : "Conheça o projeto"}
          </ActionButton>
        </Box>
      </Box>

      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Impactar outras vidas: seja um voluntário(a)!
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            color: '#FF0D0D',
            fontSize: '0.875rem'
          }}
        >
          Você é a pessoa que faltava no nosso grupo para fazer nossa ação ainda mais gigante! 
          Traga a sua disposição e habilidades para impactar outras vidas. Esperamos por você!
          {action.skills?.length > 0 && (
            <>
              <br />
              Caso tenha habilidades em: {action.skills.map(skill => skill.name).join(', ')}.
            </>
          )}
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Galeria
        </Typography>
        <GalleryCards action={action} type="gallery" />
      </Box>

      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Prestação de Contas
        </Typography>
        <GalleryCards action={action} type="accountability" />
      </Box>

      <Box>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            mb: 2 
          }}
        >
          <Typography variant="h6">
            Objetivos para o Desenvolvimento Sustentável da ação
          </Typography>
          <Link
            href="https://brasil.un.org/pt-br/sdgs"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ 
              textDecoration: 'none',
              color: 'primary.main',
              fontSize: '0.875rem',
              '&:hover': { textDecoration: 'underline' }
            }}
          >
            Saiba mais
          </Link>
        </Box>
        <ODSsCards odss={action.sustainable_development_goals} />
      </Box>

      <DonationModalComponent ngo={action.ong} />
      <VolunteerModalComponent ngo={action.ong} />
    </Stack>
  );
}
